import React, {useState, useEffect} from 'react';
import config from "../../config";
import auth0Client from "../../Auth/Auth"
import axios from 'axios';
import {Table, Card, CardGroup, OverlayTrigger, Tooltip, Row} from 'react-bootstrap'



const StockValueCard = (props) => {
    const [stockValue, setStockValue] = useState({dc: 0, soh: 0, sow: 0});
    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
          getStockValue()

    },[]);

    const getStockValue = () => {
        axios.get(`${config.api.BASE_URL}/metrics/stock_value`, {
            headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`, 'shopdeploy-acc': props.sellerAccountId}
        }).then((response) => {
            console.log(response.data)
            setStockValue(response.data.stockValue)
            setIsLoading(false)
        }).catch((e) => {
            console.log(e)
            // alert("fds");
        })
    }

    const renderTable = () => {

        return(
            <>
                <Table striped hover size="sm">
                    <Card.Title>
                        {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(stockValue.dc + stockValue.sow + stockValue.soh)}
                    </Card.Title>

                    <tr >
                        <td>Stock In DCs</td>
                        <td>
                            {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(stockValue.dc)}
                        </td>
                    </tr>

                    <tr >
                        <td>Stock On The Way</td>
                        <td>
                            {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(stockValue.sow)}
                        </td>
                    </tr>

                    <tr >
                        <td>Stock On Hand</td>
                        <td>
                            {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(stockValue.soh)}
                        </td>
                    </tr>

                </Table>
            </>

        )
    }




    return (
        <Card>
            <Card.Body>
                <Card.Subtitle className="mb-2 text-muted">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                The value of your stock calculated based on the current selling price of the stock
                            </Tooltip>  } >

                        <span style={{borderBottom: "2px dotted #c4cdd5"}}>Stock Value</span>

                    </OverlayTrigger>{' '}
                </Card.Subtitle>

                {!isLoading ? renderTable() :"loading" }
            </Card.Body>
        </Card>
    );
};

export default StockValueCard;