import React from 'react';
import RepricingAdd from "./Actions/RepricingAdd";



const OffersBulkOptions = (props) => {
    return (
        <>
            <small className={'pe-3'}> {props.selectedCount} selected</small>
            <RepricingAdd count={props.selectedCount} bulkUpdate={props.bulkUpdate} />
        </>

    );
};

export default OffersBulkOptions;