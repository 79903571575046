import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Modal, Form} from "react-bootstrap";
import EditBillingInfo from "./EditBillingInfo";

const BillingInfo = (props) => {
    
    return(<>
            <Row className="justify-content-md-center mb-5">
                <Col xs lg="3">
                    <strong>Billing Information</strong>
                </Col>
                <Col md="5" >
                    <Row>
                        <Col xs lg="6">
                            <div><strong>Company Name</strong> </div>
                            <div>{props.organisation.companyName}</div>
                            <div>{props.organisation.vatNumber}</div>
                        </Col>
                        <Col xs lg="6">
                            <div><strong>Address</strong> </div>
                            <div>{props.organisation.address}</div>
                            <div>{props.organisation.suburb}</div>
                            <div>{props.organisation.city}</div>
                            <div>{props.organisation.postcode}</div>
                        </Col>
                        <Col xs lg="6">
                            <div><strong>Contact</strong> </div>
                            <div>{props.organisation.firstName} {props.organisation.lastName}</div>
                            <div>{props.organisation.billingEmail}</div>
                        </Col>
                    </Row>
                    <EditBillingInfo {...props} />
                    <Row>
                    </Row>
                </Col>
            </Row>
        </>
    )
};

export default BillingInfo;