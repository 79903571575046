import React from 'react'
import {Button} from 'react-bootstrap'

const handleClick = (data,props) => {
    props.onPageChange(props.page + data)
      window.scrollTo({
        top: 0,
        behavior: 'instant'          
    });
  
};   

const Paginate = (props) => {
    return(
        <>
        <Button disabled={(props.page <=0)}  variant="outline-primary" onClick={ (event)=>{ event.preventDefault(); handleClick(-1, props)}}>Prev</Button>{' '}
        <Button disabled={(props.page+1 === props.totalPages)} variant="outline-primary" onClick={ (event)=>{ event.preventDefault(); handleClick(1, props)}}>Next</Button>{' '}
        </>

    )
}


export default Paginate