import React from 'react'
import {Button, Form } from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';

library.add(faSearch);

const handleSearchSubmit = (props) => {
    props.submitCallback()
}

const handleSearchChange = (callback) => { 
   //callback()
}


const Search = (props) => {

    return (<>

               <Form data-clarity-unmask="true" className='d-flex me-auto w-100' onSubmit={ (event)=>{
                                                event.preventDefault(); 
                                                props.submitCallback()
                                                }} >
                    <Form.Control data-clarity-unmask="true" className="me-3" type="text" placeholder="Search" onChange={(event)=>props.updateCallback(event.target.value)} />
                    <Button className="text-nowrap" variant="outline-success" type="submit"> 
                        <FontAwesomeIcon icon='search'/> <span className='d-none d-lg-inline'>Search</span>
                    </Button>
                </Form>
                
    </>)
}

export default Search