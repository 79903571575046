import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";
library.add(faStar)
const StarRating = ({ rating }) => {
    return (
        <div className={'d-inline-block'}>
            {rating >= 1 ? (
                <div>
                    <FontAwesomeIcon
                        icon={'star'}
                        size="1x"
                        color={rating >= 1 ? "gold" : "lightgray"}
                        style={{display: 'inline-block'}} />
                    <span className={'ps-1'}>{rating}</span> </div>
            ) : (
                <span>-</span>
            )}
        </div>
    );
};

export default StarRating;