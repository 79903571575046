
import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import config from "../../config";
import auth0Client from "../../Auth/Auth";
import {Table, Spinner} from 'react-bootstrap';

const Invoices = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);


    useEffect(() => {

        getInvoices()


    },[])
    const getInvoices = () => {
        axios.get(`${config.api.BASE_URL}/admin/invoices`, {
            headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`} })
            .then(res => {setInvoices(res.data.invoices);
                            setIsLoading(false)})
    }


    const Charge = (invoiceId) => {
        axios.post(`${config.api.BASE_URL}/admin/invoices/${invoiceId}/payment`, {},
            {headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`} })
            .then(res => {console.log(res)},
                err => {console.log(err)})

    }

    const ChargeButton = (invoice) => {
        return(
<>
            {invoice.hasValidPaymentMethod && <button className="btn btn-primary btn-sm" onClick={() => Charge(invoice.id)}>Charge ({invoice.balance})</button>}
    </>



        )
    }

    const TableRows = () => {
        return(
            invoices.map((invoice, index) => {
                return(

                    <tr key={index}>
                        <td>{invoice.dueDate}</td>
                        <td>{invoice.invoiceNumber}</td>
                        <td>{invoice.companyName}</td>
                        <td>{invoice.billingEmail}</td>
                        <td>{invoice.status}</td>
                        <td>{invoice.total}</td>
                        <td>{invoice.balance}</td>
                        <td>{invoice.paymentCount}</td>
                        <td><ChargeButton {...invoice} /></td>


                    </tr>
                )
            })
        )
    }



    return (
        <div>
            {!isLoading &&
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Due Date</th>
                    <th>Invoice</th>
                    <th>Organisation</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Balance</th>
                    <th>Attempts</th>
                    <th></th>

                </tr>
                </thead>
                <tbody>
                <TableRows/>
                </tbody>
            </Table>}
            
        </div>
    );
};

export default Invoices;