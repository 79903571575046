import { library } from "@fortawesome/fontawesome-svg-core";
import { faSlidersH, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import OfferDetails from "../../components/Product/OfferDetails";
import {
  BuyBoxStatus,
  SKUButton,
  StockAvailability,
  TSINButton,
} from "../../components/Shared/OfferShared";
import Remove from "./Remove";
library.add(faSlidersH);
library.add(faTrash);

const RenderRow = (props) => {
  return props.offers.map((offer, i) => (
    <div key={offer.id}>
      <div className={"d-flex"}>
        <div className="p-2 align-items-center w-100">
          <div className={"d-flex"}>
            <div
              className={"d-flex align-items-center pe-2"}
              style={{ msFlex: " 0 0 23px", flex: "0 0 23px" }}
            >
              <img
                src={offer.imageUrl}
                style={{ mixBlendMode: "multiply", height: "45px" }}
              />
            </div>
            <div>
              <h6>{offer.title}</h6>
              <div className={"d-flex justify-content-start"}>
                <TSINButton value={offer.tsin} url={offer.takealotUrl} />
                <SKUButton value={offer.sku} />
              </div>
            </div>
          </div>

          <div
            style={{ fontSize: "14px" }}
            className={"d-flex justify-content-between pt-2 flex-wrap"}
          >
            <div
              className={"mt-2 card bg-light d-flex"}
              style={{ minWidth: "141px" }}
            >
              <div className={"card-body align-items-center d-flex p-3"}>
                <span className={"pe-2"}>
                  <strong>Repricing: </strong>
                </span>{" "}
                <FontAwesomeIcon
                  size="2x"
                  color={offer.repricingEnabled ? "#00C851" : "#979ea5"}
                  icon={"sliders-h"}
                />
              </div>
            </div>

            <div
              className={"mt-2 card bg-light d-flex align-middle"}
              style={{ minWidth: "141px" }}
            >
              <div className={"card-body align-items-center d-flex p-3"}>
                <span className={"pe-2"}>
                  <strong>Buy Box: </strong>
                </span>{" "}
                <BuyBoxStatus {...offer} />
              </div>
            </div>

            <div
              className={"mt-2 card bg-light d-flex "}
              style={{ minWidth: "141px" }}
            >
              <div className={"card-body align-items-center d-flex p-3"}>
                <span className={"pe-2"}>
                  <strong>Stock:</strong>{" "}
                </span>{" "}
                <StockAvailability {...offer} />
              </div>
            </div>
          </div>

          <div
            style={{ fontSize: "14px" }}
            className={"d-flex justify-content-between pt-3 flex-wrap"}
          >
            <div>
              <span className={"pe-2"}>
                <strong>Min Price:</strong>
              </span>{" "}
              <NumberFormat
                value={offer.minPrice}
                fixedDecimalScale
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R"}
                decimalScale={0}
              ></NumberFormat>
            </div>
            <div>
              <span className={"pe-2"}>
                <strong>Buy Box Price:</strong>
              </span>{" "}
              <NumberFormat
                value={offer.buyBoxPrice}
                fixedDecimalScale
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R"}
                decimalScale={0}
              ></NumberFormat>{" "}
            </div>
            <div>
              <span className={"pe-2"}>
                <strong>Max Price:</strong>
              </span>{" "}
              <NumberFormat
                value={offer.maxPrice}
                fixedDecimalScale
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R"}
                decimalScale={0}
              ></NumberFormat>{" "}
            </div>
          </div>
        </div>

        {/*<div className="p-2">Flex item</div>*/}
        {/* <div className="ms-auto ps-3 p-2 align-items-center d-flex"> */}
        <div className="ms-auto ps-3 p-2 align-items-center d-flex flex-column justify-content-center">
          <Row>
            <Col>
              <OfferDetails
                key={offer.id}
                offer={offer}
                updateOffer={props.updateOffer}
              />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <Remove
                count={1}
                bulkRemoveFromRepricer={() => {
                  props.bulkRemoveFromRepricer(offer.id);
                }}
              />
              {/* <Button
                className="text-nowrap"
                variant="outline-secondary"
                style={{ padding: "0.20rem 0.37rem" }}
              >
                <FontAwesomeIcon icon={"trash"} />
              </Button> */}
            </Col>
          </Row>
        </div>
        {/* </div> */}
      </div>
      <hr />
    </div>
  ));
};

const MobileDisplay = (props) => {
  return (
    <>
      <div>
        <RenderRow
          offers={props.offers}
          updateOffer={props.updateOffer}
          bulkRemoveFromRepricer={props.bulkRemoveFromRepricer}
        />
      </div>
    </>
  );
};

export default MobileDisplay;
