import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import history from "./history";
import App from './App';
import * as serviceWorker from './serviceWorker';

import { PostHogProvider} from 'posthog-js/react'

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
}

ReactDOM.render(
    <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
    >
<Router history={history}>
    <App />
  </Router>
    </PostHogProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
