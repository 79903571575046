import React, { Component } from 'react'
import StockValueCard   from "./Home/StockValueCard";
import config from "../config";
import auth0Client from "../Auth/Auth"
import axios from 'axios';
import {Table, Card, CardGroup, OverlayTrigger, Tooltip, Row} from 'react-bootstrap'


export default class ListPromotions extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          isLoading: true,
          promotions: []
          
        };
      }
    
       componentDidMount(){
        this.setPromotions()
      }
    
      async setPromotions(){
        this.setState({isLoading:true})
        let promotions = await this.getPromotions()
        this.setState({promotions})
        this.setState({isLoading:false})
    
      }
    
      componentDidUpdate(prevProps) {
        if (prevProps.date.startDate !== this.props.date.startDate || prevProps.date.endDate !== this.props.date.endDate)  {
          this.setPromotions()
        }
      }


      async getPromotions(){
    
        let result = await axios.get(`${config.api.BASE_URL}/metrics/promotions`, {
          params: {start: this.props.date.startDate, end:this.props.date.endDate},
          headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`, 'shopdeploy-acc': this.props.sellerAccountId,
        }
       
       });
       return result.data
    }

    drawTable(){
        return(<>
             <Card.Title>
            {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(this.state.promotions.reduce((a, b) => a + (b['value'] || 0), 0))}
            </Card.Title>
            {this.state.promotions.map(
                (item, i) =>
                  <tr key={i}>       
                  <td>{item.name}</td>
                  <td>
                  {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(item.value)}
                  </td>                
                </tr>
              )}  
               
              </>
        )
    }

    renderPromotions(){
        return(<>
         <Table striped hover size="sm">
        {this.state.promotions.length > 0 ? this.drawTable(): "There were no promotion sales in this date range."}

</Table>
      
            
            
            
        </>
        )
    }


    render() {

        return (
        
            <CardGroup className="mt-md-3">
            <Card> 
            <Card.Body>
            <Card.Subtitle className="mb-2 text-muted">
                 <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                             The total revenue generated by promotions within the selected time period
                        </Tooltip>  } >
                     
                         <span style={{borderBottom: "2px dotted #c4cdd5"}}>Promotions</span>
                     
                     </OverlayTrigger>{' '}
                     </Card.Subtitle>

                {!this.state.isLoading ? this.renderPromotions() :"loading" }
                </Card.Body>
            </Card>
            <StockValueCard sellerAccountId={this.props.sellerAccountId}/>

            </CardGroup>
            
        )
    }
}
