import React, { useState } from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import {faChartLine, faTrash} from '@fortawesome/free-solid-svg-icons';
import Pluralize from "react-pluralize";
import { renderToString } from 'react-dom/server'

import {userNotification} from "../../UserNotification";
library.add(faChartLine);

const RepricingAdd = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);

    const result = () => {
        return (Pluralize({singular: 'offer', count: 2}).to_string())
    }

    const handleAdd = () => {
        setLoading(true);
        props.bulkUpdate({updates: {reprice_added: true}}).then(
            (res) => {
                setLoading(false);
                handleClose();
                res.data.successful.length > 0 && userNotification(` ${res.data.successful.length} ${res.data.successful.length > 1 ? 'offers' : 'offer'} successfully added to Repricer`, 'success')

                res.data.errors.map((error) => {
                    userNotification(`${error.ids.length} ${error.ids.length > 1 ? 'Offers' : 'Offer'}, ${error.name}`, 'danger')})

            }, (err) => {
                setLoading(false);
                handleClose();
                userNotification('Error adding offers to Repricer', 'danger');
            })
    }

    return (
        <>
            <Button className="text-nowrap" size={'sm'} variant="outline-secondary" onClick={handleShow}>
                <FontAwesomeIcon icon='chart-line'/> <span className='d-none d-lg-inline'>Add to Repricer</span>
            </Button>

            <Modal show={show} onHide={handleClose} centered animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add <Pluralize singular={'offer'} count={props.count} /> to Repricer? </Modal.Title>
                </Modal.Header>
                <Modal.Body>Offers added to the Repricer will appear on the Repricing page. On this page you will be able to set your Minimum and Maximum prices and enable repricing.</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button style={{width:'120px'}} variant="success" onClick={handleAdd}  >
                        {!loading?
                            'Add' :
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

// Archiving products will hide them from your sales channels and Shopify admin. You’ll find them using the status filter in your product list.
export default RepricingAdd;