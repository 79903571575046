import React from 'react';
import {Badge, Button, ButtonGroup, Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {store} from "react-notifications-component";



const truncate = (input) => {
    if (input.length > 75) {
        return input.substring(0, 75) + '...';
    }
    return input;
}

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)

    let message = {
        message: 'Copied to Clipboard',
        type: 'success',                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
            duration: 1000
        }}
    store.addNotification(message)

}

const OrdersProductBlock = (props) => {
    return (<>
        <Col lg={12} className="orderID"><i>#{props.orderItemId}</i></Col>
            <Col lg={12}><strong>{truncate(props.productTitle)}</strong></Col>
            <Col lg={12} >
                <ButtonGroup className="me-1">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Click to copy SKU
                            </Tooltip>  } >
                        <Button variant="sku" onClick={() => copyToClipboard(props.sku) } ><FontAwesomeIcon size='s' icon="copy" /> {props.sku}</Button>
                    </OverlayTrigger>
                </ButtonGroup>
                <ButtonGroup className="me-1">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Click to copy TSIN
                            </Tooltip>  } ><Button variant="tsin" onClick={() => copyToClipboard(props.tsin)}><FontAwesomeIcon size='s' icon="copy" /> {props.tsin}</Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                View on Takealot
                            </Tooltip>  } ><Button variant="tsin" href ={props.takealotUrl} target='_blank'> <FontAwesomeIcon size='s' icon="external-link-alt" /></Button>
                    </OverlayTrigger>

                </ButtonGroup>

                { (props.promotion) &&
                    <ButtonGroup>
                        <Badge bg="primary"><FontAwesomeIcon size='s' icon="flag" /></Badge>
                    </ButtonGroup>}
            </Col>
        </>
    );
};

export default OrdersProductBlock;