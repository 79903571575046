import React from 'react';

const LearnMore = (props) => {
    return (
        <div className={'text-center pb-5'}>
            <small>Learn more about <a href={props.link} target='_blankl'>{props.title}</a></small>
        </div>
    );
};

export default LearnMore;