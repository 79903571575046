import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Modal, Button, Spinner, Alert, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import config from "../../config";
import auth0Client from "../../Auth/Auth"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';

library.add(faCheck)
library.add(faExclamation)

const ProcessingFile = (props) => {

    
    const [upload, setUpload] = useState({status:'waiting', errors:[], successCount: 0, errorCount: 0});
    const [loading, setLoading] = useState(false);
    const [seconds, setSeconds] = useState(0)
    const interval = useRef(null)
    
    const startCounter = () => interval.current = setInterval(() => {
        getUpload()
        
      }, 1000)

    const stopCounter = () => clearInterval(interval.current)  

    const tsinErrors = upload.errors.map(item => (
        <li key={item.tsin}>
          {item.tsin} :  {item.error}
        </li>
      ));

      const failedErrors = upload.errors.map((item,index) => (
        <li key={index}>
          {item}
        </li>
      ));  


    const getUpload = async () => {
        
        //setLoading(true)
        axios.get(`${config.api.BASE_URL}/uploads/${props.uploadId}`, {
            headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`, 'shopdeploy-acc': props.currentAccountId}
            
           }).then((response) => { 
               setUpload(response.data)
               if (response.data.status === 'completed' || response.data.status === 'failed') {
                stopCounter()
               }
               //stopCounter()
               //setLoading(false) 
            })
        .catch((e) => {
            // alert(e);
          
        })
    }

    const renderButtonContents =() => {
      switch(upload.status) {
        case 'processing': 
        case 'waiting': 
          return(
            <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          /> )
          case 'completed': 
            return(
              <FontAwesomeIcon icon={'check'} />
            )
          case 'failed': 
              return (
                <FontAwesomeIcon icon={'exclamation'} />
              )
          default: 
                      }
       
    }


    const renderButtonColour =() => {

      switch(upload.status) {
        case 'waiting': 
          return 'light'
        case 'processing': 
          return 'light'
        case 'completed': 
          return 'success'
        case 'failed': 
          return 'danger'
        default: 
          return 'primary'
                      }
       
    }
  
    const renderModelTitle =() => {

      switch(upload.status) {
        case 'waiting': 
          return 'Processing File...'
        case 'processing': 
          return 'Processing File...'
        case 'completed': 
          return 'Processing Complete'
        case 'failed': 
          return 'Error Occurred While Processing File'
        default: 
          return 'primary'
                      }
       
    }


    useEffect(() => {
      startCounter()
      

        return () => stopCounter()
      },[]);

      
    return(<>
        <Modal.Header closeButton>
                <Modal.Title>{renderModelTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {upload.status !== 'failed' ?
             ( <>
                <Row>
                    <Col><Alert variant={"success"}> Updated: {upload.successCount}</Alert></Col>
                    <Col><Alert variant="danger"> Errors: {upload.errorCount}</Alert></Col>
                 </Row>
                <div>{tsinErrors} </div> 
                </>) : <Alert variant="danger">{failedErrors}</Alert> }
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button variant={renderButtonColour()} disabled>
                  {renderButtonContents()}
                </Button>
            </Modal.Footer>
            </>
    )

}

export default ProcessingFile