
import React, { Component, useState } from 'react'
import { Button, Dropdown, DropdownButton, Row, Stack, Form, Col } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'
library.add(faCalendarAlt)
const ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Last 90 Days': [moment().subtract(89, 'days'), moment()],
    'Last 365 Days': [moment().subtract(364, 'days'), moment()],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last Year': [moment().subtract(1, 'year').startOf('year'),moment().subtract(1, 'year').endOf('year')],
    'Week to Date': [moment().startOf('week'), moment().endOf('week')],
    'Month to Date': [moment().startOf('month'), moment()],
    'Year to Date': [moment().startOf('year'), moment()]
}

export default class PeriodSelector extends Component {
constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      alias: 'Last 30 Days',
      period: 'day'
    }  
  }

  customRange(period_selector_dates){
      return(
          { currentPeriod: {
                  start: period_selector_dates.start,
                  end: period_selector_dates.end},
              previousPeriod: this.calculatePreviousPeriod({
                  start: period_selector_dates.start,
                  end: period_selector_dates.end
              }, this.setPeriodType(period_selector_dates.alias))
          }
      )
  }

  standardRange(period_selector_dates){
    return(
        { currentPeriod: {
                start: ranges[period_selector_dates.alias][0].format('YYYY-MM-DD'),
                end: ranges[period_selector_dates.alias][1].format('YYYY-MM-DD')},
            previousPeriod: this.calculatePreviousPeriod({
                start: ranges[period_selector_dates.alias][0].format('YYYY-MM-DD'),
                end:ranges[period_selector_dates.alias][1].format('YYYY-MM-DD')
            }, this.setPeriodType(period_selector_dates.alias))
        }
    )
  }

    determineSavedPeriod(period_selector_dates){
        if (period_selector_dates.alias === 'Custom Range'){
            return this.customRange(period_selector_dates)
        } else {
            return this.standardRange(period_selector_dates)
        }
    }

    defaultPeriod(){
    return { currentPeriod: {
            start: moment().subtract(29, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
        },  previousPeriod: {
            start: moment().subtract(59,'days').format('YYYY-MM-DD'),
            end: moment().subtract(30,'days').format('YYYY-MM-DD')
        },
        alias: 'Last 30 Days',
        period: 'day'
    }}




    async setInitialState(period_selector_dates){

        if (period_selector_dates){
            const savedPeriod = this.determineSavedPeriod(period_selector_dates)
            this.setState({...savedPeriod,...period_selector_dates})
        } else{
            this.setState(this.defaultPeriod)
        }
    }

async componentDidMount(){
    this.setState({isLoading: true})
    const period_selector_dates = JSON.parse(localStorage.getItem('period_selector'))
    await this.setInitialState(period_selector_dates)
    this.setState({isLoading: false})
    this.props.eventHandler(this.state.currentPeriod,this.state.previousPeriod, this.state.period, this.state.alias )


}


calculatePreviousPeriod(params, timePeriod){

  switch(timePeriod){
    case 'year':
      return {start: moment(params.start).subtract(1, 'year').format('YYYY-MM-DD') , end: moment(params.end).subtract(1, 'year').format('YYYY-MM-DD') }
      break;
    case 'lastYear':
      return {start: moment(params.start).subtract(1, 'year').startOf('year').format('YYYY-MM-DD') , end: moment(params.end).subtract(1, 'year').endOf('year').format('YYYY-MM-DD') }
      break;
    case 'month':
      return {start: moment(params.start).subtract(1, 'month').format('YYYY-MM-DD') , end: moment(params.end).subtract(1, 'month').format('YYYY-MM-DD') }
      break;
    case 'lastMonth':
      return {start: moment(params.start).subtract(1, 'month').format('YYYY-MM-DD') , end: moment(params.end).subtract(1, 'month').endOf('month').format('YYYY-MM-DD') }
      break;
    case 'week':
      return {start: moment(params.start).subtract(1, 'week').format('YYYY-MM-DD') , end: moment(params.end).subtract(1, 'week').format('YYYY-MM-DD') }
      break;  
    case 'day':
      return {start: moment(params.start).subtract(moment(params.end).diff(moment(params.start),'days')+1, 'day').format('YYYY-MM-DD') , end: moment(params.start).subtract(1, 'day').format('YYYY-MM-DD') }
      break;  
  }
}

setPeriodType(timePeriod){
  // eslint-disable-next-line default-case
  switch(timePeriod){
        case 'Today':
          return 'day'
          break;
        case 'Yesterday':
          return 'day'
          break;  
        case 'Last 7 Days':
          return 'day'
          break;  
        case 'Last 30 Days':
          return 'day'
          break;  
        case 'Last 90 Days':
          return 'day'
          break; 
        case 'Last 365 Days':
          return 'day'
          break; 
        case 'Month to Date':
          return 'month'
          break;  
        case 'Last Month':
          return 'lastMonth'
          break;
        case 'Last Year':
          return 'lastYear'
          break;
        case 'Week to Date':
          return 'week'
          break;  
        case 'Year to Date':
          return 'year'
          break;    
        case 'Custom Range':
          return 'day'
          break;    
      }
    
}

options = (event, picker) => {
    const current = {start: picker.startDate.format('YYYY-MM-DD'), end:picker.endDate.format('YYYY-MM-DD')}
    let previous = this.calculatePreviousPeriod(current,this.setPeriodType(picker.chosenLabel))

    this.setState({currentPeriod: current,previousPeriod: previous, alias: picker.chosenLabel})
    localStorage.setItem('period_selector', JSON.stringify({alias: this.state.alias, period: this.state.period, ...this.state.currentPeriod}))
    this.props.eventHandler(this.state.currentPeriod,this.state.previousPeriod, this.state.period, this.state.alias )
    window.analytics.track('Changed Date Range ', {
    period: picker.chosenLabel
    });

      }

changeGranularity = (period) => {
    window.analytics.track('Changed Granularity ', {
      period: period,
    });

    this.setState({period:period})
    localStorage.setItem('period_selector', JSON.stringify({alias: this.state.alias, period: period, ...this.state.currentPeriod}))
    this.props.eventHandler(this.state.currentPeriod,this.state.previousPeriod, period, this.state.alias )

}

    render() {
        return (

          <>
              {!this.state.isLoading &&
            <Row className='pb-3'>
              <Col lg={'auto'}>  
              <div style={{display:'flex'}} >
                <DateRangePicker locale={{format: 'YYYY-MM-DD'}} startDate={this.state.currentPeriod.start} endDate={this.state.currentPeriod.end} showDropdowns onApply={this.options} ranges={ranges} >
                  <Button size="md" variant='outline-secondary'>
                  <FontAwesomeIcon icon="calendar-alt" /> <span> </span>
                  {this.state.alias != "Custom Range" ? this.state.alias: `${this.state.currentPeriod.start} to ${this.state.currentPeriod.end}`}
                </Button> 
                </DateRangePicker>
              
              <div className="ps-2 pe-2 d-flex align-items-center" >
                <div>grouped by</div>
              </div>
               
               <DropdownButton size="md" variant='outline-secondary' style={{textTransform: 'capitalize'}} title={this.state.period}>
                   <Dropdown.Item onClick={()=>this.changeGranularity('hour')}>Hour</Dropdown.Item>
                   <Dropdown.Item onClick={()=>this.changeGranularity('day')}>Day</Dropdown.Item>
                   <Dropdown.Item onClick={()=>this.changeGranularity('week')}>Week</Dropdown.Item>
                   <Dropdown.Item onClick={()=>this.changeGranularity('month')}>Month</Dropdown.Item>
                   <Dropdown.Item onClick={()=>this.changeGranularity('quarter')}>Quarter</Dropdown.Item>
                   <Dropdown.Item onClick={()=>this.changeGranularity('year')}>Year</Dropdown.Item>
                </DropdownButton>
                </div>
              </Col>
                
                <Col className="ps-lg-0" >
                    <div className="d-flex h-100" >
                      <div className="justify-content-center align-self-center">compared to {this.state.previousPeriod.start} - {this.state.previousPeriod.end}</div>
                    </div>
                 </Col>
            </Row>}



             </> 
        )
    }
}








