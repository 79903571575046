import React from 'react'
import {ToggleButton, ToggleButtonGroup, Dropdown } from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faSortAmountUpAlt, faSortAmountDown, faSort } from '@fortawesome/free-solid-svg-icons'

library.add(faSortAmountUpAlt)
library.add(faSortAmountDown)
library.add(faSort)

const Sort = (props) => {return(<>
<Dropdown onSelect={(e) => {props.callback(e)}}>
      <Dropdown.Toggle variant="outline-secondary" bsPrefix="btn" className="text-nowrap" >
         <FontAwesomeIcon icon="sort" /> <span className='d-none d-lg-inline'> Sort</span>
      </Dropdown.Toggle>
      <Dropdown.Menu >
          {props.options.map((option, index) => {
            return <Dropdown.Item key={index} active={props.selected === option.value } eventKey={option.value}>{option.label}</Dropdown.Item>
          }
            )}
      </Dropdown.Menu>
</Dropdown>
      
<ToggleButtonGroup name="direction" type="radio" value={props.direction} onChange={(e) => {props.directionCallback(e)}} >  
  <ToggleButton name="direction" id='1' variant="outline-secondary" value={'desc'} ><FontAwesomeIcon size='s' icon="sort-amount-down" /></ToggleButton>
  <ToggleButton name="direction" id='2' variant="outline-secondary" value={'asc'}><FontAwesomeIcon size='s' icon="sort-amount-up-alt" /></ToggleButton>
</ToggleButtonGroup>

</>)}

export default Sort