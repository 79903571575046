const dev = {

  api :{
    BASE_URL: process.env.REACT_APP_API_ENDPOINT
  },
  auth0: {
    BASE_URL: "http://localhost:8000",
    CALLBACK_URL: "http://localhost:8000/callback",
  }

};

const staging = {

  api :{
    BASE_URL: "https://api2.shopdeploy.com"
  },
  auth0: {
    BASE_URL: process.env.REACT_APP_DEPLOY_PRIME_URL,
    CALLBACK_URL: process.env.REACT_APP_DEPLOY_PRIME_URL+"/callback",
  }

};


// const staging = {

//    api :{
//      BASE_URL: "https://test-api.shopdeploy.com/"
//     },
//     auth0: {
//      BASE_URL: 'http://wwww.google.com',
//       CALLBACK_URL: 'http://wwww.google.com',
//     }
  
//    };


const prod = {
  // s3: {
  //   REGION: "YOUR_PROD_S3_UPLOADS_BUCKET_REGION",
  //   BUCKET: "YOUR_PROD_S3_UPLOADS_BUCKET_NAME"
  // },
  api: {
    BASE_URL: "https://api.shopdeploy.com"
  },

  auth0: {
    BASE_URL: "https://app.shopdeploy.com",
    CALLBACK_URL: "https://app.shopdeploy.com/callback",
  }

};

// Default to dev if not set
// const config = process.env.REACT_APP_STAGE === 'prod'
//   ? prod
//   : dev;
let config = {}
  if (process.env.REACT_APP_STAGE === 'prod') {
      config = prod
  } else if (process.env.REACT_APP_STAGE === 'staging') {
     config = staging
  } else {
      console.log("loading dev...")
     config = dev

  }

// console.log("CONFIG: ")
// console.log(config)

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};