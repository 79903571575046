import React from 'react'

export default function ListOrdersTable(props) {

    return (
        <div>
            This is a table 
            {props.tableData}
 

        </div>
    )
}
