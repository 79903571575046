import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {store} from "react-notifications-component";
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";

library.add(faSearch)

const NoOffersFound = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: '20vh'}}>
            <div className="text-center">
                <FontAwesomeIcon size='5x' icon="search" style={{color: "#adb5bd"}} />
                <h1>No Offers Found</h1>
                <p>Try adjusting your search filters</p>
            </div>
        </div>
    );
};


export default NoOffersFound;