import React from 'react';
import {Placeholder, Table} from "react-bootstrap";
import BulkCheckbox from "./Repricing/BulkCheckbox";
import BulkOptions from "./Repricing/BulkOptions";

const TableLoading = () => {


    return (
        <>
            <Table hover responsive size="sm"  className={``}>
                <thead key={3231} style={{height: '50px'}} >
                <tr>
                    <td className="align-middle col-md-2"><Placeholder xs={12} size="xs"/></td>
                    <td className="align-middle col-md-2"><Placeholder xs={12} size="xs"/></td>
                    <td className="align-middle col-md-1"><Placeholder xs={12} size="xs"/></td>
                    <td className="align-middle col-md-1"><Placeholder xs={12} size="xs"/></td>
                    <td className="align-middle col-md-3"><Placeholder xs={12} size="xs"/></td>



                </tr>
                </thead>
                <tbody>

                {[...Array(12)].map((k,v)=> {
                    return(<tr key={v} style={{height: '50px'}}>
                        <td  className="align-middle"><Placeholder xs={12} size="xs"/></td>
                        <td  className="align-middle"><Placeholder xs={12} size="xs"/></td>
                        <td  className="align-middle"><Placeholder xs={12} size="xs"/></td>
                        <td  className="align-middle"><Placeholder xs={12} size="xs"/></td>
                        <td  className="align-middle"><Placeholder xs={12} size="xs"/></td>
                    </tr>)

                })}

                </tbody>
                   </Table>

</>

    );
};

export default TableLoading;