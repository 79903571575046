import React from 'react';
import Form from 'react-bootstrap/Form';
const BulkCheckbox = (props) => {

    return (
        <Form.Check >


            <Form.Check.Input type={'checkbox'}  style={{height: '18px', width: '18px'}}
                              checked={props.checked}
                              onChange={props.onChange}
                              label={'default-checkbox'}
            />

        </Form.Check>
    );
};

export default BulkCheckbox;