import React, {useState} from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import { trackButtonClicked, customEvent } from "../../segment/analytics";


const EditBillingInfo = (props) => {
    const [organisation, setOrganisation] = useState(props.organisation);
    const [showBilling, setShowBilling] = useState(false);
    const handleClose = () => setShowBilling(false);
    const handleShow = () => {setShowBilling(true);
        trackButtonClicked('Billing Info Modal Opened');
    }

    const onOrgChange = (e) => {
        setOrganisation({...organisation,[e.target.name]: e.target.value}
        )
    }

    const onSave = async () => {
        await props.callback({
            companyName: organisation.companyName,
            firstName: organisation.firstName,
            lastName: organisation.lastName,
            address: organisation.address,
            suburb: organisation.suburb,
            city: organisation.city,
            postcode: organisation.postcode,
            phoneNumber: organisation.phoneNumber,
            billingEmail: organisation.billingEmail,
            vatNumber: organisation.vatNumber})
        handleClose()
        customEvent('Billing Info Updated', {
            companyName: organisation.companyName
        })
    }

    return ( <>
        <Button className="mt-2" variant={"outline-dark"} onClick={handleShow}>Update Billing</Button>
        <Modal show={showBilling} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Billing Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                        type="text" value={organisation.companyName} name={'companyName'} onChange={onOrgChange}/>
                </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                        <Form.Label>Vat Number</Form.Label>
                        <Form.Control
                            type="text" value={organisation.vatNumber} name={'vatNumber'} onChange={onOrgChange}/>
                    </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Billing Email Address</Form.Label>
                    <Form.Control type="email" value={organisation.billingEmail} name={'billingEmail'} onChange={onOrgChange}/>
                </Form.Group>

                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text" value={organisation.firstName} name={'firstName'} onChange={onOrgChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" value={organisation.lastName} name={'lastName'} onChange={onOrgChange}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" value={organisation.address} name={'address'} onChange={onOrgChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control type="text" value={organisation.suburb} name={'suburb'} onChange={onOrgChange}/>
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" value={organisation.city} name={'city'} onChange={onOrgChange}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" value={organisation.postcode} name={'postcode'} onChange={onOrgChange}/>
                        </Form.Group>
                    </Col>
                </Row>

            </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default EditBillingInfo;