import React from "react";
import { Route, Switch } from "react-router-dom";

import Auth from './Auth/Auth';
//import Home from "./containers/Home";
import Orders from "./containers/Orders";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Callback from "./components/Callback";
import Inventory from "./containers/Inventory"
import Signup from "./containers/Signup"
import Welcome from "./containers/Welcome"
import OffersShow from "./containers/OffersShow"
import Offers from "./containers/Offers"
import Repricing from "./containers/Repricing"
import Account from "./containers/Account"
import Settings from "./containers/Settings"
import SellerAccounts from "./containers/admin/SellerAccounts"
import Home2 from "./containers/Home2";
import Integrations from "./containers/Integrations";
import Billing from "./containers/Billing";
import Invoices from "./containers/admin/Invoices";
import Organisations from "./containers/admin/Organisations";



export default ({ childProps } ) =>
    <Switch>
          <AuthenticatedRoute path="/" exact component={Home2} props={childProps} />
            <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
            <UnauthenticatedRoute path="/callback" exact component={Callback} props={childProps} />
            <AuthenticatedRoute path="/orders" exact component={Orders} props={childProps} />
            <AuthenticatedRoute path="/offers" exact component={Offers} props={childProps} />
            <AuthenticatedRoute path="/offers/:id" exact component={OffersShow} props={childProps} />
            <AuthenticatedRoute path="/inventory" exact component={Inventory} props={childProps} />
            <AuthenticatedRoute path="/repricing" exact component={Repricing} props={childProps} />
            <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />
            <AuthenticatedRoute path="/welcome" exact component={Welcome} props={childProps} />
            <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
            <AuthenticatedRoute path="/integrations" exact component={Integrations} props={childProps} />
            <AuthenticatedRoute path="/billing" exact component={Billing} props={childProps} />
            <AuthenticatedRoute path="/admin/seller_accounts" exact component={SellerAccounts} props={childProps} />
            <AuthenticatedRoute path="/admin/invoices" exact component={Invoices} props={childProps} />
            <AuthenticatedRoute path="/admin/organisations" exact component={Organisations} props={childProps} />
      { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;




