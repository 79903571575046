import React, { Component } from 'react'

import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import {Spinner} from 'react-bootstrap';
import auth0Client from "../Auth/Auth"
import axios from 'axios';

import config from "../config";

export default class DataChart extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          isLoading: true,
          graphData: []
          
        };
    }
    
    componentDidMount(){
        this.setGraphData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date.startDate !== this.props.date.startDate || prevProps.date.endDate !== this.props.date.endDate || prevProps.period !== this.props.period )  {
          this.setGraphData()
        }
      }

    async setGraphData(){
        this.setState({isLoading:true})
        let graphData = await this.getGraphData()
        this.setState({graphData})
        //console.log("set: "+ graphData)
        this.setState({isLoading:false})
    }

    async getGraphData(){
        let result = await axios.get(`${config.api.BASE_URL}/metrics/graph`, {
            params: {start: this.props.date.startDate, end:this.props.date.endDate, period: this.props.period, type: this.props.graphEndpoint},
            headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`, 'shopdeploy-acc': this.props.sellerAccountId,
          }
         
         });
       
         //console.log(result.data)
         return result.data
    }


    renderGraph(){
      // var d = new Date();
      // console.log('date')
      // let start = Date.parse(this.state.graphData.data[0][0])
      // d.setDate(d.getDate()-5);
        const defaults = {
        data: {
          parseDate: Date.parse,
            // dateFormat: '%e of %b'
        },time: {
                useUTC: false,
                },
            series: [
                      {
                        name: this.props.children,
                                    
                        data: (() => {
                          return this.state.graphData.data.map(function(point) {
                            return [Date.parse(point[0]), point[1]]
                          })
                        })(),
                        marker: {
                            enabled: false},
                            
                          },
                    ]

          }

          const options = {...this.props.highChartOptions,...defaults}


          return(
            <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
          )

    }

    render() {
        //console.log(this.props)
        return (
            <div>

                {!this.state.isLoading?  this.renderGraph():
                        <div className="d-flex justify-content-center"> 
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                        </div>
                 
                  }
            </div>
        )
    }
}
