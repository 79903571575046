import React from 'react'
import { NavLink  } from "react-router-dom";
import auth0Client from '../Auth/Auth.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt,faBars, faTags, faInbox, faWarehouse, faChartBar, faDatabase, faRobot, faChartLine, faCog, faPlug, faCreditCard, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import {differenceInDays} from 'date-fns';
import Pluralize from 'react-pluralize'
library.add(faInbox)
library.add(faChartBar)
library.add(faWarehouse)
library.add(faSyncAlt)
library.add(faBars)
library.add(faTags)
library.add(faDatabase)
library.add(faRobot)
library.add(faChartLine)
library.add(faCog)
library.add(faPlug)
library.add(faCreditCard)
library.add(faExclamationTriangle)

const SideNav = (props) => {

    return(
        <>
        <ul className="nav nav-pills flex-column mb-auto pt-2" style={{'fontSize': '15px'}}>
                { auth0Client.isAuthenticated() &&   
                    <>
                    <li className="nav-item">
                        <NavLink className="nav-link" exact to="/" onClick={()=>{props.handleClose && props.handleClose()}}> <span className="pe-2"><FontAwesomeIcon icon="chart-bar" /></span>Dashboard</NavLink>  
                     </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/orders" onClick={()=>{props.handleClose && props.handleClose()}}>  <span className="pe-2"><FontAwesomeIcon icon="inbox" /></span>Orders</NavLink>  
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/inventory" onClick={()=>{props.handleClose && props.handleClose()}}> <span className="pe-2"><FontAwesomeIcon icon="warehouse" /></span>Inventory</NavLink>
                    </li> 
                    </>     
                }
               
                {auth0Client.isAuthenticated() && 
                    !props.sellerAccount.data.reporting && !props.isLoading &&
                        <>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/offers" onClick={()=>{props.handleClose && props.handleClose()}}>  <span className="pe-2"><FontAwesomeIcon icon="tags" /></span>Offers</NavLink>    
                        </li> 
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/repricing" onClick={()=>{props.handleClose && props.handleClose()}}> <span className="pe-2"><FontAwesomeIcon icon="chart-line" /></span>Repricing</NavLink>
                        </li>  
                        </>
                }
                 { auth0Client.isAuthenticated() &&
                     <>

                     <li className="nav-item">
                     <NavLink className="nav-link" to="/integrations" onClick={()=>{props.handleClose && props.handleClose()}}> <span className="pe-2"><FontAwesomeIcon icon="plug" /></span>Integrations</NavLink>
                     </li>
                </>
                 }
                </ul>

            <div className="dropdown mb-3">

                <ul className="nav nav-pills red flex-column mb-auto" style={{'fontSize': '15px'}}>
                    { auth0Client.isAuthenticated() && !props.sellerAccount.data.onTrial && props.organisation && props.organisation.overdue &&
                        <li className='nav-item'>
                            <div className="nav-link" style={{'padding-bottom': '10px', 'padding-top': '10px', background: 'rgb(255 0 0 / 31%)', color: '#d30f0f'}}>
                                <span className="pe-2"><FontAwesomeIcon icon="exclamation-triangle" /></span>
                                <span className="justify-content-end"><span style={{fontWeight: '600'}}>Overdue Account</span></span>
                            </div>
                        </li>}

                    { auth0Client.isAuthenticated() && props.sellerAccount.data.onTrial &&
                        <li className='nav-item'>
                            <div className="nav-link" style={{'paddingBottom': '10px', 'paddingTop': '10px', background: 'rgb(255 213 0 / 78%)'}}>
                                <NavLink to="/billing" style={{textDecoration: 'none', color: 'rgba(0,0,0,0.85)'}}> <span className="pe-2"><FontAwesomeIcon icon="exclamation-triangle" /></span>
                                <span className="justify-content-end"><span style={{fontWeight: '600'}}><Pluralize singular={'day'} count={Math.max(0, differenceInDays(props.sellerAccount.data.trialEndsAt,Date.now()))}></Pluralize> left</span></span></NavLink>
                            </div>
                        </li>}


                    { auth0Client.isAuthenticated() && props.sellerAccount.data.billingOwner &&
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/billing" onClick={()=>{props.handleClose && props.handleClose()}}> <span className="pe-2"><FontAwesomeIcon icon="credit-card" /></span>Billing</NavLink>
                        </li>
                    }
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/settings" onClick={()=>{props.handleClose && props.handleClose()}}> <span className="pe-2"><FontAwesomeIcon icon="cog" /></span>Settings</NavLink>
                    </li>
                </ul>

            </div></>
        
    )
}

export default SideNav