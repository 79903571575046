import React, { useEffect, useRef } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import auth0Client from "../Auth/Auth";
import Welcome from "../containers/Welcome";
import Suspended from "../containers/Suspended";
import { Spinner, Alert } from "react-bootstrap";

function AuthenticatedRoute(allProps) {
  const { component: C, props: cProps, path, ...rest } = allProps;
  const history = useHistory();
  const goLogin = () => history.push("billing");
  const isMounted = useRef(false);

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (cProps.checkingSession)
          return (
            <h3 className="text-center">
              <Spinner animation="grow" />
            </h3>
          );

        if (!auth0Client.isAuthenticated()) {
          console.log("auth not authenticated");
          auth0Client.signIn();
          return <div></div>;
        }

        if (!cProps.sellerAccount.data.signupComplete) {
          return <Welcome {...props} {...cProps} />;
        }

        if (cProps.organisation.suspended) {
          window.analytics.page("Account Disabled");
          return <Suspended {...props} {...cProps} />;
        }
        //
        if (
          cProps.organisation.billingRedirect &&
          isMounted.current === false
        ) {
          isMounted.current = true;
          goLogin();
        }

        return (
          <div>
            {!cProps.sellerAccount.data.sellerAccount.jobsActive && (
              <Alert variant={"danger"}>
                Takealot API Key requires updating.
              </Alert>
            )}
            <C {...props} {...cProps} />
          </div>
        );
      }}
    />
  );
}

export default AuthenticatedRoute;
