import React, { useState, useCallback,useEffect } from 'react'
import {Modal, Button, Offcanvas} from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'

library.add(faSlidersH)

const FilterSelector = (props) => { 

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)                                            
    const handleShow = () => setShow(true);

    return(<>
    <Button className="text-nowrap" variant="outline-secondary" onClick={handleShow}><FontAwesomeIcon icon='sliders-h'/> <span className='d-none d-lg-inline'> Filters</span></Button>


    <Offcanvas show={show} onHide={handleClose} placement={'end'} backdrop={false}>
        <Offcanvas.Header closeButton style={{'border-bottom': '1px solid #c6cbd1'}}>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        
        {props.children}
        <div className="footer">
          
        <Button variant="secondary" onClick={handleClose} >Done</Button></div>
        </Offcanvas.Body>
      </Offcanvas>
    </>)
  }

export default FilterSelector
