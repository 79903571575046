import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import { BrowserTracing } from "@sentry/tracing";
import auth0Client from "./Auth/Auth.js";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSyncAlt,
  faBars,
  faTags,
  faWarehouse,
  faDatabase,
  faRobot,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Helmet } from "react-helmet";
import ReactNotifications from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import config from "./config";
import { inject } from "@vercel/analytics";
import NavHeader from "./components/NavHeader";
import SideNav from "./components/SideNav";
import "./App.css";

import LogRocket from "logrocket";
import { Spinner } from "react-bootstrap";
import { pageViewed } from "./segment/analytics";

import { Mixpanel } from "./components/Shared/Mixpanel";
import posthog from "posthog-js";

const hConfig = {
  apiKey: "hbp_jTSh5rf5f2F9llbD40dymvbL8VrQrN1zyhEu",
  environment: "production",
  revision: "git SHA/project version",
};
//
// LogRocket.init("k9tp2p/shopdeploy");

library.add(faWarehouse);
library.add(faSyncAlt);
library.add(faBars);
library.add(faTags);
library.add(faDatabase);
library.add(faRobot);
library.add(faChartLine);

inject();


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkingSession: true,
      sellerAccount: null,
      organisation: { billingRedirect: false },
    };
  }

  async componentDidMount() {
    if (this.props.location.pathname === "/callback") {
      this.setState({ checkingSession: false });
      return;
    }

    try {
      await auth0Client.silentAuth();

      let user = await this.getUserInfo();
      this.setState({ sellerAccount: user });
      let org = await this.getOrganisation();
      this.setState({ organisation: org.data });
      await this.setDriftData();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== "login_required") console.log(err.error);
    }
    this.setState({ checkingSession: false });
  }

  getUserInfo() {
    return axios.get(`${config.api.BASE_URL}/users`, {
      headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` },
    });
  }

  getOrganisation() {
    return axios.get(`${config.api.BASE_URL}/organisation`, {
      headers: {
        Authorization: `Bearer ${auth0Client.getIdToken()}`,
        "shopdeploy-acc": this.state.sellerAccount.data.currentAccountId,
      },
    });
  }

  async setDriftData() {
    const profile = await auth0Client.getProfile();

    this.setIdentityData();
  }

  setIdentityData() {
    console.log("Setting identity data");
    const profile = auth0Client.getProfile();
    LogRocket.identify(profile.sub, {
      email: profile.name,
    });
    posthog.identify(
        profile.sub, // Required. Replace 'distinct_id' with your user's unique identifier
        { email: profile.name,  },  // $set, optional

    );


    Mixpanel.identify(profile.sub);
    window.analytics.identify(profile.sub, {
      email: profile.name,
      signupComplete: this.state.sellerAccount.data.signupComplete,
      firstName: this.state.sellerAccount.data.firstName,
      lastName: this.state.sellerAccount.data.lastName,
    });

    window.clarity("identify", profile.name);

    console.log(this.state.organisation);
  }

  setUserAccount(accData) {
    //This function is passed to the callback component

    this.setState({ sellerAccount: accData });
    this.setIdentityData();
  }

  getUserAccount() {
    return axios.get(`${config.api.BASE_URL}/users`, {
      headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` },
    });
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogin = async (event) => {
    auth0Client.signIn();
    //console.log("User Authenitcated.. ")
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      checkingSession: this.state.checkingSession,
      setUserAccount: this.setUserAccount.bind(this),
      //setSignupState: this.setSignupState.bind(this),
      //signupComplete: this.state.signupComplete,
      sellerAccount: this.state.sellerAccount,
      billingRedirect: true,
      organisation: this.state.organisation,
    };

    return (
      <div>
        <Helmet titleTemplate="%s | Shopdeploy" />
        <ReactNotifications />

        {!this.state.checkingSession ? (
          <>
            <NavHeader {...childProps} />
            <div className="wrapper">
              <aside>
                <SideNav {...childProps} />
              </aside>
              <main className="main" style={{ marginTop: "4.5rem" }}>
                <div className="wrapper_inner">
                  <div className="container-fluid">
                    <Routes childProps={childProps} />
                  </div>
                </div>
              </main>
            </div>
          </>
        ) : (
          <div style={{ height: "100vh" }}>
            <div className="loader">
              <div className="text-center">
                <Spinner animation="border" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(App);
