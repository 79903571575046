import React, {useState} from 'react';
import {Card, Col, Row, Button, Modal, Form} from 'react-bootstrap'

import axios from "axios";

const CustomIntegration = (props) => {

    const [showCustom, setShowCustom] = useState(false);
    const [query, setQuery] = useState({message: ''});
    const [sellerAccount, setSellerAccount] = useState({sellerAccount: props.sellerAccount.data.currentAccountId, email: props.sellerAccount.data.email});
    const [formStatus, setFormStatus] = useState(false);
    const handleCloseCustom = () => setShowCustom(false);
    const handleShowCustom = () => setShowCustom(true);

    const handleChange = (event) => {
        setQuery({message: event.target.value});
    }

    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const submitCustomRequest = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.entries({...query, ...sellerAccount}).forEach(([key, value]) => {
            formData.append(key, value);
        });

        axios
            .post(
                "https://getform.io/f/7bad85c6-52c5-4ac3-bd3c-6a0eb611014b",
                formData,
                { headers: { Accept: "application/json" } }
            )
            .then(function (response) {
                setFormStatus(true);
                setQuery({
                    message: ''
                });


            })
            .catch(function (error) {
                console.log(error);
            });

        await sleep(2000)
        handleCloseCustom();
        setFormStatus(false);

    }

    return (
        <>
            <Col>
                <Card >
                    <Card.Body>
                        <Card.Title>Request Integration</Card.Title>
                        <Card.Text>
                           Let us know what tools and services you would like to integrate with.
                        </Card.Text>
                        <Button variant="success" onClick={handleShowCustom}>Submit Request</Button>
                        <Modal
                            show={showCustom}
                            onHide={handleCloseCustom}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Request Integration</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">

                                        <Form.Label>Integration details</Form.Label>
                                        <div className={'text-muted'}>Please provide the details of the integration you'd like</div>
                                        <Form.Control as="textarea" rows={3} type="text" onChange={handleChange} value={query.message} />

                                    </Form.Group>

                                    {formStatus ? (
                                        <div className="alert alert-success">
                                            Your message has been sent.
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={submitCustomRequest} >Submit</Button>
                            </Modal.Footer>
                        </Modal>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default CustomIntegration;