import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Card } from 'react-bootstrap';
import { NavLink  } from "react-router-dom";

const UsageBar = (props) => {
    const percentage = (props.used/ props.total) *100
    
    return (
        <Card style={{width: '245px'}}>
            <Card.Body>
                <ProgressBar style={{height:'3px'}} now={percentage} variant={percentage >= 100 ? 'danger' : 'primary'}/>
                <div className={'d-flex'}>
                   <small className={'text-muted'}>{props.used}/{props.total} Tracked </small>
                    {percentage >= 100 && props.isBillingOwner && <small className={'ms-auto text-nowrap'}>
                       <NavLink className={'text-decoration-none'} to="/billing">
                           <strong>Upgrade Now</strong>
                       </NavLink>
                   </small>}
                </div>
            </Card.Body>
        </Card>
    );
};

export default UsageBar;