import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import './Percentage.css';
library.add(faArrowUp)
library.add(faArrowDown)

function arrow(number){
    if (number > 0){
        return <span className="negative"><FontAwesomeIcon icon="arrow-up" />{number.toFixed(2)}%</span>
    } else if(number < 0 ) {
        return <span className="positive"><FontAwesomeIcon icon="arrow-down" />{(number*-1).toFixed(2)}%</span>
    } else{
        return <span>-</span>
    }
}


const PercentageInverse = (props) => {
    return(
    <OverlayTrigger
    placement="right"
    overlay={
        <Tooltip id={`tooltip-right`}>
             {props.display}
        </Tooltip>  } >
     
         {/* <span style={{borderBottom: "2px dotted #c4cdd5"}}>Promotions</span> */}
         <span style={{borderBottom: "2px dotted #c4cdd5"}} >{arrow((((props.endNum-props.startNum)/props.startNum)*100))}</span>
     </OverlayTrigger>
    )


}


export default PercentageInverse;