import React, { useState, useCallback,useEffect } from 'react'
import {Modal, Button} from 'react-bootstrap'
import UploadFile from "../components/FileUpload/UploadFile"
import ProcessingFile from "../components/FileUpload/ProcessingFile"


const FileUploader = (props) => {   
    const [show, setShow] = useState(false);
    const [uploadId, setUploadId] = useState(false);
    const handleClose = () => setShow(false)                                            
    const handleShow = () => setShow(true);
    const modalProps = {handleClose, setUploadId, uploadId, ...props}
    const buttonTitle = {repricing: 'Import', costs: 'Import Costs'}

    return(

    <>
    <Button variant="outline-secondary" onClick={handleShow}>{buttonTitle[props.uploadType]}</Button>
            
            <Modal 
            show={show} 
            onHide={handleClose}
            // triggered after the transition
            onExited={()=> setUploadId(null)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
            scrollable={true}>
                {!uploadId?  <UploadFile {...modalProps}/> :<ProcessingFile {...modalProps}/> }     
                
        </Modal>
        </>


    )


}

export default FileUploader