import React, {useState, useEffect, forwardRef, useRef} from 'react';
import {
    OverlayTrigger,
    Spinner,
    Table,
    Tooltip
} from "react-bootstrap";
import TableLoading from "../TableLoading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {store} from "react-notifications-component";
import {
    faExclamationTriangle,
    faHourglassHalf,
    faTimesCircle,
    faBell,
    faBoxOpen
} from '@fortawesome/free-solid-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";
import NumberFormat from "react-number-format";
import RepricingSwitch from "./RepricingSwitch";
import BulkCheckbox from "./BulkCheckbox";
import InputBox from "./InputBox";
import OfferDetails from "../Product/OfferDetails";
import {StockAvailability, StockFulfilment, BuyBoxStatus, OffersProductBlock} from "../../components/Shared/OfferShared"
import BulkOptions from "./BulkOptions";
library.add(faHourglassHalf)
library.add(faExclamationTriangle)
library.add(faTimesCircle)
library.add(faBell)
library.add(faBoxOpen)



const BuyBoxPrice = (props) => {
    if (props.isAvailable) {
        return <NumberFormat value={props.buyBoxPrice} fixedDecimalScale displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={0}></NumberFormat>
    } else
        return "-"
}


const MinOrMaxDot = (props) => {
 if (props.minReached)
     return <OverlayTrigger overlay={(<Tooltip id="hi">Min Price Reached</Tooltip>)}><span className={'dot me-1'} style={{backgroundColor: "orange"}}></span></OverlayTrigger>
    else if (props.maxReached)
        return <OverlayTrigger overlay={(<Tooltip id="hi">Max Price Reached</Tooltip>)}><span className={'dot me-1'} style={{backgroundColor: "green"}}/></OverlayTrigger>
    else return <></>
}

const RenderTableRows = (props) => {
    const [activeInputBox, setActiveInputBox] = useState(-1);
    const target = useRef(null)


    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setActiveInputBox(-1)
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);
    const updateActiveInputBox = (id) => {
        // setActiveInputBox(activeInputBox !== id ? id : -1);
        setActiveInputBox(id);
    }

    const updateMaxPrice = ({id, value}) =>{
        return props.updateOffer({offerId: id, payload: {maxPrice: value}})
    }

    const updateMinPrice = ({id, value}) =>{
        return props.updateOffer({offerId: id, payload: {minPrice: value}})
    }

    return (
<>
    {props.isSearching &&
        <Spinner className="spinner-table" animation="border" role="status">
            <span className="sr-only">Loading...</span>
       </Spinner>
    }
     <tbody style={{verticalAlign:'middle'}}>
        {
            props.offers.map((offer, i) =>
                <tr key={offer.id} className={`${!props.isSearching ? "" : "opacity-test"} ${props.selectedOffers.includes(offer.id) && 'table-selected'}`}>
                    <td className='compact'><BulkCheckbox onChange={() => props.offerSelect(offer.id)} checked={props.selectedOffers.includes(offer.id)}/></td>
                    <td className='compact pe-1 ps-1'><img src={offer.imageUrl} style={{mixBlendMode: 'multiply', height:'45px' }} /></td>
                    <td className='ps-1' style={{'width': '550px','paddingLeft': '0!important'}}><OffersProductBlock {...offer}/></td>
                    <td className='text-center compact'> <RepricingSwitch {...offer} callback={props.updateOffer} key={offer.repricingEnabled}/></td>
                    <td className='d-none d-xl-table-cell text-center compact'> <StockAvailability {...offer}/></td>
                    <td className='d-none d-xxl-table-cell text-center compact'><StockFulfilment {...offer}/></td>
                    <td className='text-center compact'>
                            <BuyBoxStatus {...offer} />
                    </td>
                    <td className='d-none d-xxl-table-cell text-end compact '>{offer.sellerCount > 0 ? offer.sellerCount : '-'}</td>
                    <td className='text-end compact' style={{padding: '5px 20px'}}><BuyBoxPrice {...offer} /></td>
                    <td className='text-end compact'><InputBox id={offer.id}
                                                               value={offer.minPrice}
                                                               active={activeInputBox === offer.id+'min'}
                                                               onClick={()=> updateActiveInputBox(offer.id+'min')}
                                                               callback={updateMinPrice}
                                                               activeState={updateActiveInputBox}
                                                               comparison={'less'}
                                                               comparisonValue={offer.maxPrice}
                                                               key={offer.minPrice}/></td>
                    <td className='text-end compact'><MinOrMaxDot className={'me-1'}  {...offer}/><NumberFormat value={offer.sellingPrice} fixedDecimalScale displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={0} /></td>
                    <td className='text-end compact'><InputBox id={offer.id}
                                                               value={offer.maxPrice}
                                                               active={activeInputBox === offer.id+'max'}
                                                               onClick={() => updateActiveInputBox(offer.id+'max')}
                                                               callback={updateMaxPrice}
                                                               activeState={updateActiveInputBox}
                                                               comparison={'greater'}
                                                               comparisonValue={offer.minPrice}
                                                               key={offer.maxPrice}/>
                    </td>
                    <td className='text-end compact'><OfferDetails key={offer.id} offer={offer} updateOffer={props.updateOffer} /></td>
                </tr>)
        }
        </tbody>
    </>
    )

}

const DesktopTable = (props) => {

    return (
        <>
        {/*<div className={`d-flex ${props.selectedOffers.length === 100 ? 'd-none' : ''}`} style={{'height':'50px'}}>*/}

            <div style={{'height':'50px'}} className={`d-flex ${props.selectedOffers.length === 0 ? 'd-none' : ''}`}>
            <div className="d-flex h-100">
                <div className="align-self-center" style={{'paddingLeft':'15px'}}>
                        <BulkCheckbox onChange={props.selectAllOffers} checked={props.selectedOffers.length === props.offers.length}/>
                </div>
                <div className="align-self-center ps-3">
                        <BulkOptions selectedCount={props.selectedOffers.length} bulkRemoveFromRepricer={props.bulkRemoveFromRepricer}/>
                </div>
            </div>
            </div>
            {/*<BulkOptions/>*/}
        {/*</div>*/}
        <Table hover responsive size="sm"  className={`offer-table ${props.selectedOffers.length > 0 ? 'hide-table-header' : ''}`}
               style={{fontSize: '13px'}}>

            <thead className={`align-middle`} >
            <tr>
                <th><BulkCheckbox onChange={props.selectAllOffers} checked={(props.selectedOffers.length === props.offers.length) && props.offers.length > 0}/></th>
                <th></th>
                <th>Product</th>
                <th className='text-center'>Repricing <br />Status</th>
                <th className='d-none d-xl-table-cell text-center no-wrap'>Stock <br />Availability</th>
                <th className='d-none d-xxl-table-cell text-center'>Fulfillment</th>
                <th className='text-center' style={{'whiteSpace': 'nowrap'}}>Buy Box <br />Status</th>
                <th className='d-none d-xxl-table-cell' style={{textAlign:'right', 'whiteSpace': 'nowrap'}}>Sellers</th>
                <th style={{textAlign:'right', 'whiteSpace': 'nowrap', padding: '5px 20px'}}>Buy Box <br />Price</th>
                <th style={{textAlign:'right', 'whiteSpace': 'nowrap'}}>Your Min <br />Price</th>
                <th style={{textAlign:'right', 'whiteSpace': 'nowrap'}}>Your Current <br />Price</th>
                <th style={{textAlign:'right', 'whiteSpace': 'nowrap'}}>Your Max <br />Price</th>
                <th style={{textAlign:'right', 'whiteSpace': 'nowrap'}}></th>
                {/*<th style={{textAlign:'right', 'whiteSpace': 'nowrap'}} >Net</th>*/}
                {/*<th style={{textAlign:'right', 'whiteSpace': 'nowrap'}} >%</th>*/}
            </tr>
            </thead>
            {/*<tbody style={{verticalAlign:'middle'}}>*/}
                < RenderTableRows offers={props.offers} updateOffer={props.updateOffer} isSearching={props.isSearching} removeFromRepricer={props.removeFromRepricer} offerSelect={props.offerSelect} selectedOffers={props.selectedOffers}/>
            {/*</tbody>*/}
        </Table>
        </>
    );
};

export default DesktopTable;