import React, { useState, useCallback } from 'react'
import {Form, Button, Collapse} from 'react-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
library.add(faChevronUp)
library.add(faChevronDown)


const useToggle = (initialState) => {
    const [isToggled, setIsToggled] = useState(initialState);
  
    // put [setIsToggled] into the useCallback's dependencies array
    // this value never changes so the callback is not going to be ever re-created
    const toggle = useCallback(
      () => setIsToggled(state => !state),
      [setIsToggled],
    );
  
    return [isToggled, toggle];
  }


function updateSelectedChoice(value, checked, selected, allowMultiple= false) {
    if (checked) { 
        return allowMultiple ? [...selected, value] : [value];
   
    } else {
    
        return selected.filter((selectedChoice) => selectedChoice !== value);
    }
 
}

function handleChange(value, checked, selected, allowMultiple, callback) {
    callback(updateSelectedChoice(value, checked, selected, allowMultiple))
    
}

const ChoiceList = (props) => {
    const [isToggled, toggle] = useToggle(false);
    return(
        <>
        <div className="d-grid gap-2">
        <Button className='d-flex justify-content-between' variant="outline-secondary" size="md" onClick={toggle} style={{border: '0px solid transparent'}} >
              <b>{props.title} </b>
              <div><FontAwesomeIcon  icon={isToggled? 'chevron-down': 'chevron-up'} /></div>
        </Button>
        </div>

        <Collapse in={isToggled}>
             <div className="p-3">

             {props.options.map((checkbox, index) => (
                       <Form.Check 
                       type={props.allowMultiple ? 'checkbox' : 'radio'}
                       label={checkbox.label}
                       value={checkbox.value}
                       checked={props.selected.includes(checkbox.value)}
                       onChange={(e) => {handleChange(e.target.value,e.target.checked,props.selected, props.allowMultiple, props.onChange )}}
                       //onChange={props.onChange}
                       
                     />
                 ))}
     
     
                 <small className="text-muted" onClick={() => props.onChange([])}><a href='#'>Clear</a></small>
                 </div>
                 </Collapse>
        {/* //} */}
        
        
       
        </>

    )
}

export default ChoiceList