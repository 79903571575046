import React, { Component } from "react";
import loading from "./loading.svg";
import auth0Client from "../Auth/Auth";
import config from "../config";
import axios from "axios";
import { pageViewed } from "../segment/analytics";

class Callback extends Component {
  async componentDidMount() {
    auth0Client.handleAuthentication().then(
      (suc) => {
        this.getUserInfo().then((res) => {
          this.props.setUserAccount(res);
          this.props.history.replace("/");
        });
      },
      (err) => {
        console.log("err", err);
        auth0Client.signOut();
      }
    );

    //this.setState({sellerAccount: await this.getUserInfo()})
    // this.props.setUserAccount(await this.getUserInfo())
  }

  getUserInfo() {
    return axios.get(`${config.api.BASE_URL}/users`, {
      headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` },
    });
    // .then((res) => {
    //   console.log(res);
    //   return res;
    // });
  }

  render() {
    const style = {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      height: "10vh",
      width: "100vw",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "white",
      marginTop: "100px",
    };

    return (
      <div style={style}>
        <img src={loading} alt="loading" />
      </div>
    );
  }
}

export default Callback;

//https://auth0.com/blog/react-tutorial-building-and-securing-your-first-app/#Securing-your-React-App
