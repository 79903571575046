import React, { Component } from "react";
import './Home.css';
import auth0Client from "../Auth/Auth"
import PeriodSelector from '../components/PeriodSelector'
import DailyMetrics from '../components/DailyMetrics'
import MostPopularTable from '../components/MostPopularTable';
import DataChart from '../components/DataChart'
import DashboardCards from '../components/DashboardCards'
import ListPromotions from '../components/ListPromotions'

import {Link} from "react-router-dom"
import axios from 'axios';
import {Row, Col, Card, Container, CardGroup, Button, Alert} from 'react-bootstrap';
import config from "../config";
import {Helmet} from "react-helmet";
import { pageViewed } from "../segment/analytics";


export default class Home2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoading: true,
        dailyIsLoading: true,
        datePickerLoaded: false,
        // currentPeriod: {
        //     start: moment().subtract(29, 'days').format('YYYY-MM-DD'),
        //     end: moment().format('YYYY-MM-DD')
        // },
        // previousPeriod: {
        //     start: moment().subtract(59,'days').format('YYYY-MM-DD'),
        //     end: moment().subtract(30,'days').format('YYYY-MM-DD')
        // },
        // period: 'day',
        // alias: 'Last 30 Days'

    }
    
  }


 async componentDidMount(){
     pageViewed("Dashboard")
      window.analytics.identify(auth0Client.getProfile().sub, {email: auth0Client.getProfile().email})

 }
  

 metrics(params,period){
   //console.log(params)
  return axios.get(`${config.api.BASE_URL}/metrics`, {
    params: {start:params.start, end:params.end, period:period},
    //params:{period: period},
    headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId,
  }
  });
 }


async getMetrics(currentPeriod, previousPeriod, period, alias){
  this.setState({currentPeriod, previousPeriod, period, datePickerLoaded: true})
  this.setState({ isLoading: true });
  const current = await this.metrics(currentPeriod, period)
  const previous = await this.metrics(previousPeriod, period)
  this.setState({ metrics : {current: current, previous: previous} });
  this.setState({ isLoading: false, datePickerLoaded: true });

}


renderHome(){

  return(
    <div> 
      { !this.state.isLoading && this.renderBarChart(this.state.metrics.current.data.graph, this.state.metrics)
    }  
    </div>
  )

}

determinePlan(string){
  
    switch (string){
    case 'Retail':
      return {plan: 'Retail', price: 'R1499', url: 'https://paystack.com/pay/dcwcwpe4nw'}
    case 'Growth':
      return {plan: 'Growth', price: 'R799', url: 'https://paystack.com/pay/dfk5lyzox2'}
    case 'Starter':
      return {plan: 'Starter', price: 'R499', url: 'https://paystack.com/pay/eshfnpon44'}
    case 'Lite':
      return {plan: 'Lite', price: 'R249', url: 'https://paystack.com/pay/0i64py11zm'}
    case 'Custom800':
      return {plan: 'Custom', price: 'R2199', url: 'https://paystack.com/pay/4ggoqy6yx0'}
    default: 
    return {plan: 'Unknown', price: 'Unknown', url: '#'}
  
  }
  

}

renderSubscriptionRequired(){
let planDetails = this.determinePlan(this.props.sellerAccount.data.sellerAccount.planName)
  return(<Alert variant="danger">
  <Alert.Heading>Your trial is ending today</Alert.Heading>
  <p>
    Signup to a Shopdeploy paid plan in order to prevent your account from being suspended. Based on your usage, you are best suited to a <strong>{planDetails.plan} plan with a monthly fee of {planDetails.price}. </strong>
  </p>
  <hr />
        <div className="d-flex justify-content-end">
          {/* <Button variant="outline-primary">
            
          </Button> */}
          <Link to={{ pathname: planDetails.url }} className="btn btn-outline-danger" value="test" target="_blank" >Signup to {planDetails.plan}</Link>
        </div>
  </Alert>)
}



render(){

  const unitsOptions = {
    chart: {
      type: 'column',
      animation: false
    },
    title: {
      text: null
  },
    plotOptions: {
      series: {
        groupPadding: 0,
        animation: false,
        color: '#9b59b6'  
      }
  },
  xAxis: [{
    type: 'datetime',
    dateTimeLabelFormats: {
        day: '%e of %b',
        year: '%Y',
        week: '%e. %b',
        month: '%b \'%y',

        
    }, 
    crosshair: true
 }],
  yAxis: [{ // Primary yAxis
    allowDecimals: false,
    title: {
        text: null,           
    },

}],



  }


  
  const revenueOptions = {
    chart: {
      type: 'spline',
      animation: false
    },
    title: {
      text: null
  },
    plotOptions: {
      series: {
        groupPadding: 0,
        animation: false,
        color: '#2ecc71'  
      }
  },
  xAxis: [{
    type: 'datetime',
    dateTimeLabelFormats: {
        day: '%e of %b'
    }, 
    crosshair: true
}],
  yAxis: [{ // Primary yAxis
    
    title: {
        text: null,           
    },
},]
  }


  return(
    <>
    <Helmet>
      <title>Dashboard</title>
    </Helmet>  
    
    <div className="d-xs-block d-sm-block d-md-none mb-3">
      <DailyMetrics sellerAccount={this.props.sellerAccount.data.currentAccountId} />
    </div>

    <div>

        <Row>
            <Col >
                <PeriodSelector eventHandler={this.getMetrics.bind(this)} />
            </Col>
        </Row>

        {this.state.datePickerLoaded && (<>

            <CardGroup >
                <Card >
                    <DataChart graphEndpoint='units' date={{startDate: this.state.currentPeriod.start, endDate: this.state.currentPeriod.end}} period={this.state.period} highChartOptions={unitsOptions} sellerAccountId={this.props.sellerAccount.data.currentAccountId} >
                        Units
                    </DataChart>
                </Card>

                <Card >
                    <DataChart graphEndpoint='revenue' date={{startDate: this.state.currentPeriod.start, endDate: this.state.currentPeriod.end}} period={this.state.period} highChartOptions={revenueOptions} sellerAccountId={this.props.sellerAccount.data.currentAccountId} >Revenue</DataChart>
                </Card >
            </CardGroup>

            <DashboardCards currentPeriod={this.state.currentPeriod} previousPeriod={this.state.previousPeriod} sellerAccountId={this.props.sellerAccount.data.currentAccountId} />
            <ListPromotions date={{startDate: this.state.currentPeriod.start, endDate: this.state.currentPeriod.end}} sellerAccountId={this.props.sellerAccount.data.currentAccountId} />
            <MostPopularTable date={{startDate: this.state.currentPeriod.start, endDate: this.state.currentPeriod.end}} sellerAccountId={this.props.sellerAccount.data.currentAccountId} />

        </>) }


    </div>

           
           </>
         
    )
}

}