
import { store } from 'react-notifications-component';

export function userNotification(text, type){
 
  let message = {
    message: text,
    type: type,                         // 'default', 'success', 'info', 'warning'
    container: 'bottom-center',                // where to position the notifications
    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
    dismiss: {
      duration: 2400
    }} 
  return store.addNotification(message)
  
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text)

  userNotification('Copied to Clipboard', 'success')

}

