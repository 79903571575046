import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import InputGroup from 'react-bootstrap/InputGroup';
import {faEdit, faCheck} from '@fortawesome/free-solid-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {userNotification} from "../UserNotification";
import NumberFormat from "react-number-format";

library.add(faEdit)
library.add(faCheck)


const InputBox = (props) => {
    
    const [isSaving, setIsSaving] = useState(false);
    const [value, setValue] = useState(props.value);
    const [initValue, setInitValue] = useState(props.value);
    const [inputDigitsValid, setInputDigitsValid] = useState(true);
    const [inputCompareValid, setInputCompareValid] = useState(true);

    const updateValue = (event) => setValue(event.target.value);


    useEffect(() => {
        // Reset to intial value if user clicks on a different input box
        setValue(initValue)
    }, [props.active]);

    // useEffect(() => {
    //     setInitValue(props.value)
    //     setValue(props.value)
    // }, [props.value]);

    useEffect(() => {

        //validtion that only digits
        const re = /^[0-9\b]+$/;

        if (value !== '' && !re.test(value)) {
            setInputDigitsValid(false)

        } else {
            setInputDigitsValid(true)
        }


        if  (props.comparisonValue !== null) {
                switch(props.comparison) {
                    case 'greater':
                        if (props.comparisonValue >= value )
                            setInputCompareValid(false)
                        else
                            setInputCompareValid(true)
                        break;
                    case 'less':
                        if ( props.comparisonValue <= value)
                            setInputCompareValid(false)
                        else
                            setInputCompareValid(true)
                        break;
                    default:
                        break;
        }}

    }, [value]);


    const ActiveInputBox = (e) => {
        return(<Form.Control
            type="text"
            className={'text-end input-box-box input-box-input'}
            size={'sm'}
            value={value}
            style={{border: '1px solid #cacccd'}}
            onChange={updateValue}
            autoFocus
            inputMode="numeric"
            data-clarity-unmask="true"
        />)
    }

    const handleSave = (event) => {

        event.preventDefault();
        if (!(inputDigitsValid && inputCompareValid)) {
            return;
        }

        setIsSaving(true);

        props.callback({id: props.id, value: Number(value)})
        .then(
                (response) => {
                    setInitValue(value);
                    userNotification('Successfully Updated', 'success');
                    setIsSaving(false);
                    props.activeState(-1)
                    window.analytics.track("Min/Max Price Changed", {
                        id: props.id,
                        value: value
                    })
                },
                (error) => {
                    
                    userNotification(error.response.data.toString(), 'danger')
                    setIsSaving(false)
                })
    }


    const ActiveButton = (e) => {
        return(<Button
            variant="outline-secondary"
            id="button-addon-active"
            className={'input-box-button input-box-button-svg input-box-input active-button'}
            size={'sm'}
            style={(inputDigitsValid && inputCompareValid)? {border: '1px solid #cacccd', color: '#cacccd'} : {backgroundColor: '#f8d7da', borderColor: '#f5c6cb', color: '#721c24'}}
            type={'submit'}
            disabled={!(inputDigitsValid && inputCompareValid)}
        >
            {!isSaving? <FontAwesomeIcon className={'input-box-button-svg'} color={'#979ea5'} style={{color:'#979ea5' }} icon={faCheck} /> : <Spinner className={'input-box-button-svg'} color={'#979ea5'} animation="border" size="sm" /> }
        </Button>)
    }

    const InActiveButton = () => {
        return(<Button type="button" className={'btn-sm input-box-box inactive-button' } variant="outline-secondary" id="inactive-button" onClick={(event) => {event.preventDefault(); props.onClick()}}>
            <FontAwesomeIcon className={'input-box-button-svg'} size='1x' color={'#979ea5'} icon={'edit'} />
        </Button>)
    }


    return (
        <Form onSubmit={handleSave}>
        <InputGroup className="d-flex sm input-box" style={{width: '100px'}} >
            {props.active ? ActiveButton() : InActiveButton()}
            {props.active ? ActiveInputBox() :
                <NumberFormat className="text-end input-box-box form-control form-control-sm"
                              onClick={() => props.onClick()}
                              value={initValue}
                              fixedDecimalScale
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'R'}
                              decimalScale={0} />
            }
        </InputGroup>
        </Form>
    );
};

export default InputBox;