import React from 'react'
import {Nav} from 'react-bootstrap';
import SideNavItems from '../components/SideNavItems'


const SideNav = (props) => {

    return(

        <div className="flex-column p-1 text-bg-dark position-fixed shop-sidebar" style={{width: '200px',top: '3.5rem', height: 'calc(100% - 3.5rem)', boxShadow: 'inset -1px 0 0 rgb(0 0 0 / 10%)'}}>

            <SideNavItems {...props}/>

        </div>


    )

}


export default SideNav
