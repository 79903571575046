import React, { Component } from "react";

export default class Suspended extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div>
          Your account has been suspended due to non payment. Please contact
          support@shopdeploy.com to arrange payment.
        </div>
      </div>
    );
  }
}
