import React, {useState} from 'react';
import {Button, Col, Row, Modal} from "react-bootstrap";
import CreditCard from "./CreditCard";
import axios from "axios";



const PaymentDetails = (props) => {

    const [coupon, setCoupon] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const submitCoupon = () => {
        setSubmitting(true,
            axios
                .post("https://getform.io/f/a9fefc74-9198-4b0c-954f-c13a02e04d9b", {
                        code: coupon,
                        org: props.organisation.companyName,
                        email: props.organisation.billingEmail,

                    },
                    { headers: {'Accept': 'application/json'}})
                .then(response => setShow(true, setSubmitting(false)) )
            )


    }


    return (
            <>
            <Row className="justify-content-md-center mb-5">
                <Col xs lg="3">
                    <strong>Payment Details</strong>
                </Col>

                <Col md="5" >
                    < CreditCard {...props}/>
                </Col>

            </Row>

                {props.organisation.trialEndsAt !== null &&
                <Row className="justify-content-md-center mb-5">
                    <Col xs lg="3">
                        <strong>Coupon</strong>
                    </Col>

                    <Col md="5" >
                        <input type="text" className="form-control" placeholder="Enter Coupon Code" onChange={(e)=> setCoupon(e.target.value)}/>
                        <Button className="mt-2" variant={"outline-dark"} disabled={coupon===''} onClick={submitCoupon}>{submitting ? 'Saving..' : 'Submit Code'}</Button>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Coupon Code Successfully Submitted</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Your coupon code has been received and once validated we'll extend your trial. This typically takes 24 to 48 hours.</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>

                </Row> }
        </>
    );
};

export default PaymentDetails;