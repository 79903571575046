import React, { useState } from "react";
import { Button, Row, Form, Col, InputGroup, Spinner } from "react-bootstrap";
import { userNotification } from "../UserNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faQuestionCircle);
const OfferDetailsBuyBox = (props) => {
  const initialValues = {
    minPrice: props.offer.minPrice || "",
    repricingEnabled: props.offer.repricingEnabled,
    maxPrice: props.offer.maxPrice || "",
    sellingPrice: props.offer.sellingPrice || "",
    competeBestPrice: props.offer.competeBestPrice,
    ltCompeteBestPrice: props.offer.ltCompeteBestPriceEnabled,
  };

  const [prevItems, setPrevItems] = useState(props);
  const [values, setValues] = useState(initialValues);
  const [submit, setSubmit] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (props !== prevItems) {
    setPrevItems(props);
    setValues(initialValues);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    // Only Submit fields that have changed
    setSubmit({
      ...submit,
      [name]: value,
    });
  };

  const toggleRepricing = () =>
    handleInputChange({
      target: { name: "repricingEnabled", value: !values.repricingEnabled },
    });

  const toggleBestPrice = () =>
    handleInputChange({
      target: { name: "competeBestPrice", value: !values.competeBestPrice },
    });

  const toggleLtBestPrice = () =>
    handleInputChange({
      target: { name: "ltCompeteBestPrice", value: !values.ltCompeteBestPrice },
    });

  const updateRepricing = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    props.updateOffer({ offerId: props.offer.id, payload: { ...submit } }).then(
      (response) => {
        userNotification("Repricing Successfully Updated", "success");
        setIsSubmitting(false);
      },
      (error) => {
        userNotification(error.response.data.toString(), "danger");
        setIsSubmitting(false);
      }
    );
  };

  return (
    <div>
      <div className={"mb-2"}>
        <strong>Buy Box</strong>
      </div>
      <Form onSubmit={updateRepricing}>
        <Row>
          <Form.Label column="md" sm={5}>
            Repricing
          </Form.Label>
          <Col sm={4} className={"d-flex align-items-center"}>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={values.repricingEnabled}
              onChange={toggleRepricing}
            />
          </Col>
        </Row>
        <hr className="mt-2 mb-2" />
        <div>
          <div className="form-label col-form-label col-form-label-md">
            <span className="h6">Compete for best price: </span>{" "}
            <a
              href={
                "https://www.shopdeploy.com/docs/repricing/compete_for_best_price/"
              }
              target="_blank"
            >
              <FontAwesomeIcon
                size="1x"
                color={"grey"}
                icon={"question-circle"}
              />
            </a>
          </div>
        </div>
        <Row>
          <Form.Label column="md" sm={5}>
            In-Stock
          </Form.Label>
          <Col sm={4} className={"d-flex align-items-center"}>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={values.competeBestPrice}
              onChange={toggleBestPrice}
              disabled={!values.repricingEnabled}
            />
          </Col>
        </Row>
        <Row>
          <Form.Label column="md" sm={5}>
            On Lead time
          </Form.Label>
          <Col sm={4} className={"d-flex align-items-center"}>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={values.ltCompeteBestPrice}
              onChange={toggleLtBestPrice}
              disabled={!values.repricingEnabled}
            />
          </Col>
        </Row>
        <hr className="mt-2 mb-2" />

        <Row>
          <Form.Label column="md" sm={5}>
            Your Current Price
          </Form.Label>
          <Col sm={4}>
            <InputGroup className="mb-0" size="md">
              <InputGroup.Text>R</InputGroup.Text>
              <Form.Control
                inputmode="numeric"
                name={"sellingPrice"}
                onChange={handleInputChange}
                value={values.sellingPrice}
                disabled
              />
            </InputGroup>
          </Col>
        </Row>
        <hr className="mt-2 mb-2" />

        <Row>
          <Form.Label column="md" sm={5}>
            Your Min Price
          </Form.Label>
          <Col sm={4}>
            <InputGroup className="mb-0" size="md">
              <InputGroup.Text>R</InputGroup.Text>
              <Form.Control
                inputmode="numeric"
                name={"minPrice"}
                onChange={handleInputChange}
                value={values.minPrice}
              />
            </InputGroup>
          </Col>
        </Row>
        <hr className="mt-2 mb-2" />

        <Row>
          <Form.Label column="md" sm={5}>
            Your Max Price
          </Form.Label>
          <Col sm={4}>
            <InputGroup className="mb-0" size="md">
              <InputGroup.Text>R</InputGroup.Text>
              <Form.Control
                inputmode="numeric"
                name={"maxPrice"}
                onChange={handleInputChange}
                value={values.maxPrice}
              />
            </InputGroup>
          </Col>
        </Row>
        <hr className="mt-2 mb-2" />

        <Button type={"submit"} size={"md"} style={{ "min-width": "130px" }}>
          {" "}
          {isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Update Repricing"
          )}{" "}
        </Button>
      </Form>
    </div>
  );
};

export default OfferDetailsBuyBox;
