import React from 'react';
import {Button, ButtonGroup, OverlayTrigger, Tooltip, Col, Badge} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {store} from "react-notifications-component";
import { library } from '@fortawesome/fontawesome-svg-core'
import {faDollarSign} from '@fortawesome/free-solid-svg-icons';

library.add(faDollarSign);

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)

    let message = {
        message: 'Copied to Clipboard',
        type: 'success',                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
            duration: 1000
        }}
    store.addNotification(message)

}

const TSINButton = (props) => {
    return (
        <span>
        <ButtonGroup className="me-1 flex-nowrap">
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        Click to copy TSIN
                    </Tooltip>  } ><Button variant="tsin" onClick={() => copyToClipboard(props.value)}>
                <FontAwesomeIcon size='1x' icon="copy" /> {props.value}</Button>
            </OverlayTrigger>

            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        View on Takealot
                    </Tooltip>  } ><Button variant="tsin" href ={props.url} target='_blank'> <FontAwesomeIcon size='1x' icon="external-link-alt" /></Button>
            </OverlayTrigger>

        </ButtonGroup>
        </span>
    );
};

const SKUButton = (props) => {
    return(
        <span className={'flex'}>
            <ButtonGroup className="me-1">
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            Click to copy SKU
                        </Tooltip>  } >
                    <Button variant="sku" onClick={() => copyToClipboard(props.value) } ><FontAwesomeIcon size='1x' icon="copy" /> {props.value}</Button>
                </OverlayTrigger>
            </ButtonGroup>
        </span>
    )
}

const StockAvailability = (props) => {
    if (props.status === 'Buyable') {
        return <OverlayTrigger overlay={(<Tooltip id="hi">Buyable</Tooltip>)} placement="right">
            <div><FontAwesomeIcon size='2x' color={'#00C851'} icon={'box-open'} /></div></OverlayTrigger>
    } else if (props.status === 'Not Buyable'){
        return <OverlayTrigger overlay={(<Tooltip id="hi">Not Buyable</Tooltip>)} placement="right"><div><FontAwesomeIcon size='2x' color={'#979ea5'} icon={'box-open'} /></div></OverlayTrigger>
    } else {
        return <OverlayTrigger overlay={(<Tooltip id="hi">{props.status}</Tooltip>)} placement="right"><div><FontAwesomeIcon size='2x' color={'#c21010'} icon={'box-open'} /></div></OverlayTrigger>
    }
}

const BuyBoxWinner = (props) => {
    if(props.isTakealot) {
        return  <OverlayTrigger overlay={(<Tooltip id="hi">Buy Box is owned by Takealot</Tooltip>)} placement="right">
            <div><img src={'https://res.cloudinary.com/shopdeploy/image/upload/c_scale,w_50/v1670845737/logo/apple-touch-icon_ddymqg.webp'} style={{height: '25px'}} /> </div></OverlayTrigger>
    }else if (props.isWinner) {
        return <OverlayTrigger overlay={(<Tooltip id="hi">You are winning the Buy Box</Tooltip>)} placement="right">
            <div><FontAwesomeIcon size='2x' color={'#13b52f'} icon={ 'check-circle'} /></div></OverlayTrigger>
    } else {
        return <OverlayTrigger overlay={(<Tooltip id="hi">Buy Box is owned by a competitor
        </Tooltip>)} placement="right">
            <div><FontAwesomeIcon size='2x' color={'#E84545'} icon={'bell'} /></div></OverlayTrigger>
    }
}


const BuyBoxStatus = (props) => {

    if(props.isAvailable) {
        return <BuyBoxWinner {...props}/>
    } else if (props.isAvailable === null) {
        return   <OverlayTrigger overlay={(<Tooltip id="hi">Buy Box pending</Tooltip>)} placement="right">
            <div><FontAwesomeIcon size='2x' color={'#979ea5'} icon={'hourglass-half'} />
            </div></OverlayTrigger>
    }else {
        return  <OverlayTrigger overlay={(<Tooltip id="hi">No Buy Box available</Tooltip>)} placement="right">
            <div><FontAwesomeIcon size='2x' color={'rgba(242, 117, 34, 1)'} icon={'exclamation-triangle'} /> </div></OverlayTrigger>
    }
}

const StockFulfilment = (props) => {
    if (props.fulfilment === 'TAL') {
        return <span className="label " style={{color: '#0b79bf', border: '#0b79bf 1px solid'}}>TAL</span>
    } else if (props.fulfilment === 'LEADTIME'){
        return <span className="label " style={{color: '#FF8F00', border: '#FF8F00 1px solid'}}>LT</span>
    } else
        return '-'
}


const OffersProductBlock = (props) => {


    return (<div >
            <Col lg={12}><strong>{props.title}</strong></Col>
            <Col lg={12} >
                <ButtonGroup className="me-1">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Click to copy SKU
                            </Tooltip>  } >
                        <Button variant="sku" onClick={() => copyToClipboard(props.sku) } ><FontAwesomeIcon size='1x' icon="copy" /> {props.sku}</Button>
                    </OverlayTrigger>
                </ButtonGroup>

                <ButtonGroup className="me-1">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Click to copy TSIN
                            </Tooltip>  } ><Button variant="tsin" onClick={() => copyToClipboard(props.tsin)}><FontAwesomeIcon size='1x' icon="copy" /> {props.tsin}</Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                View on Takealot
                            </Tooltip>  } ><Button variant="tsin" href ={props.takealotUrl} target='_blank'> <FontAwesomeIcon size='1x' icon="external-link-alt" /></Button>
                    </OverlayTrigger>



                </ButtonGroup>

                { (props.promotion) &&
                    <ButtonGroup>
                        <Badge bg="primary"><FontAwesomeIcon size='s' icon="flag" /></Badge>
                    </ButtonGroup>}


                { (props.repricingAdded) &&
                    <ButtonGroup className="me-1">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>{props.repricingEnabled? 'Repricing Active' : 'Added to Repricing'}</Tooltip>} >

                        <Badge className={'btn'} bg={props.repricingEnabled? 'repricing' : 'repricing-added'}>Repricing <FontAwesomeIcon size='1x' icon="chart-line" /></Badge>

                    </OverlayTrigger>
                       </ButtonGroup>}

                { (props.competeBestPrice && props.repricingEnabled) &&
                    <ButtonGroup className="me-1">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-top`}>Competing for Best Price</Tooltip>} >

                            <Badge className={'btn'} bg={'primary'}> <FontAwesomeIcon size='1x' icon="dollar-sign" /></Badge>

                        </OverlayTrigger>
                    </ButtonGroup>}

            </Col>
        </div>
    );
};

export {TSINButton,SKUButton, StockAvailability, BuyBoxWinner, BuyBoxStatus,StockFulfilment, OffersProductBlock};