import {Button, Col, Row, Card} from "react-bootstrap";
import React from "react";
import ChangePlan from "./ChangePlan";
import NumberFormat from "react-number-format";


const SubscriptionDetails= (props) => {

    const QuotaCard = (props) => {

        return(
            <Card className="mb-3 text-center" bg={'light'}>
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text>
                        {props.usage}/{props.limit}
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }


    return(
        <Row className="justify-content-md-center mb-5">
            <Col xs lg="3">

                <strong>Subscription Details</strong>
                <div><small className="text-muted">Manage or change your Shopdeploy subscription</small></div>
            </Col>

            <Col md="5">
                <Row>
                    <Col xs={12}>
                        <Row xs={3}>
                            <Col>
                                <QuotaCard title={'Users'} usage={props.organisation.numberOfUsers} limit={props.organisation.plan.userLimit}/>
                            </Col>

                            <Col>
                                <QuotaCard title={'Accounts'} usage={props.organisation.numberOfSellerAccounts} limit={props.organisation.plan.sellerAccountLimit}/>
                            </Col>

                            <Col>
                                <QuotaCard title={'Repricers'} usage={props.organisation.numberOfOffersInRepricer} limit={props.organisation.plan.repricerLimit}/>
                            </Col>

                       </Row>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs lg="4">  <div><strong>Current Plan</strong> </div><div>{props.organisation.plan.name}</div></Col>
                            <Col xs lg="4">  <div><strong>Next Billing Date</strong> </div><div>{props.organisation.nextBillingAt}</div></Col>
                            <Col xs lg="4">  <div><strong>Billing Amount</strong> </div><div>< NumberFormat value={props.organisation.nextBillingAmount} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2} fixedDecimalScale /> </div></Col>
                        </Row>
                    </Col>

                </Row>

                <ChangePlan {...props} />
            </Col>
        </Row>
    )
}

export default SubscriptionDetails;