import React, { Component } from "react";
import auth0Client from '../Auth/Auth';
import {Form, Button,Card, Spinner} from "react-bootstrap";
import Stepper from 'react-stepper-horizontal';
import axios from 'axios';
import config from "../config"

export default class Welcome extends Component {
    constructor(props) {
        super(props);

        this.state = {value: '', buttonLoading: false};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
   
    async componentDidMount(){
      window.analytics.page('Welcome');
      window.analytics.track('Signed Up', {'email': auth0Client.getProfile().email, userId: auth0Client.getProfile().sub});
      window.analytics.identify(auth0Client.getProfile().sub, {email: auth0Client.getProfile().email})
    }




async submitApiKey(){
  return axios.post(`${config.api.BASE_URL}/seller_accounts`,{
    api_key: this.state.value,
   
  }, {
    headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`}
  });
  
 }   




 handleChange(event) {
    this.setState({value: event.target.value});
  }

  validateForm() {
    return this.state.value.length > 0;
  }
  

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({buttonLoading:true})
    try {
        let result = await this.submitApiKey()
        window.analytics.track('Seller Account Created');
        window.location.reload();
    }
    catch (e) {
      window.analytics.track('API Key Error', {'error': e});
      alert("The API Key already exists in Shopdeploy or the key is invalid. Please contact support@shopdeploy.com . " + e.message)
      this.setState({buttonLoading:false})
 }


   
    //alert('A name was submitted: ' + this.state.value);
    
  }  

renderTakealotDetails(){
    return(
        <>
        <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Takealot Seller API Key</Form.Label>
          <Form.Control type="text" onChange={this.handleChange} value={this.state.value} />  
          <small id="emailHelp" className="form-text text-muted">Paste your Takealot Seller API key above</small>
        </Form.Group>
  
  <Button variant="primary" type="submit" disabled={!this.validateForm()}>
  { this.state.buttonLoading && <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    /> }
   
  
  <span>Finish</span>
  </Button>
  



        </Form>



<Card body>
    <h2>Instructions on how to find your Seller API Key</h2>
    <p className="lead">Your Takealot Seller API can be found in the Seller Portal.</p>

<h4>Step 1</h4>
<p>Click this url: <a href="https://seller.takealot.com/api/seller-api" target="_blank">https://seller.takealot.com/api/seller-api</a> </p>

<p><b>OR</b></p>

<p>Login to the Takealot Seller Portal and click API INTEGRATIONS -> Seller API </p>

<h4>Step 2</h4>
<p>Scroll down to the block titled Authentication and copy and paste your API key into the form above </p>

<img className="img-fluid img-thumbnail" src="https://res.cloudinary.com/shopdeploy/image/upload/w_900/api-key-block_uewfec.png"></img>


</Card>
</>
    )
}

    renderPersonalDetails(){
        return(
            <>
           
            {/* <p class="lead">
  Tell us about yourself.
</p> */}
            <Form>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>First Name</Form.Label>
    <Form.Control type="text" placeholder="Rupert" />  
  </Form.Group>

  <Form.Group controlId="formBasicEmail">
    
    <Form.Label>Last Name</Form.Label>
    <Form.Control type="text" placeholder="Retailer" />
  
  </Form.Group>

  <Form.Group controlId="formBasicPassword">
  <Form.Label>Mobile number</Form.Label>
    <Form.Control type="tel" placeholder="082 123 4567" />
  </Form.Group>
  <Button variant="primary" type="submit">
    Next
  </Button>
</Form></>
        )
    }

    render() {

        return(
    <>
     <div>
     <h1>Welcome to Shopdeploy</h1>
    <div>
      <Stepper steps={ [{title: 'User Details'}, {title: 'Takealot API Key'}] } activeStep={ 1 } />
    </div>
    {this.renderTakealotDetails()}
    </div>
    </>
    )
}


}
