import config from "../config";

import auth0 from "auth0-js";
import { pageViewed } from "../segment/analytics";

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: "auth.shopdeploy.com",
      audience: "https://api.shopdeploy.com",
      clientID: "LKhXI22lKl2F4O2rzaqHs63GAAS4Vmts",
      redirectUri: config.auth0.CALLBACK_URL,
      responseType: "id_token",
      scope: "openid email profile",
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  signUp() {
    this.auth0.authorize({
      screen_hint: "signup",
    });
  }

  signIn() {
    pageViewed("Login");
    this.auth0.authorize();
  }

  currentSellerAccount() {
    return this.userAccount;
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash(async (err, authResult) => {
        console.log(authResult);
        console.log(err);
        if (err) return reject(err);

        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);

        //  const account = await this.getUserAccount()
        //  console.log(account)
        //this.setUserAccount(account)

        resolve();
      });
    });
  }

  setSession(authResult) {
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.idTokenPayload.exp * 1000;
  }

  signOut() {
    this.auth0.logout({
      returnTo: config.auth0.BASE_URL,
      clientID: "LKhXI22lKl2F4O2rzaqHs63GAAS4Vmts",
    });
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        resolve();
      });
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
