import React, {useEffect, useReducer, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import { usePaystackPayment } from 'react-paystack';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import { trackButtonClicked, customEvent } from "../../segment/analytics";
library.add(faCheckCircle)

const CreditCard = (props) => {


    const [hasValidPaymentMethod, sethasValidPaymentMethod] = useState(props.organisation.hasValidPaymentMethod);
    const [newPaymentMethod, setNewPaymentMethod] = useState(false);

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);



// you can call this function anything
    const onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.

        sethasValidPaymentMethod(true);
        setNewPaymentMethod(true);
        customEvent("Credit Card Updated", {reference: reference.reference});

    };

// you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        trackButtonClicked('Credit Card Modal Closed');
        forceUpdate();
    }

    const config = {
        reference: props.organisation.uuid + "_" + (new Date()).getTime().toString(),
        email: props.organisation.billingEmail,
        amount: 100,
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
        currency: 'ZAR',
        channels: ['card']
    };

    const PaystackHookExample = () => {

        const initializePayment = usePaystackPayment(config);
        return (

                <Button className="mt-2" variant={"outline-dark"} onClick={() => {
                    initializePayment(onSuccess, onClose)
                    trackButtonClicked('Open Credit Card Modal');

                }}> {hasValidPaymentMethod ? "Update" : "Enter"} Credit Card Details</Button>

        );
    };

    const CreditCardDetails = () => {
        return (
            <div> <div>Credit Card</div>
                <div>{props.organisation.cardDetails.brand} </div>
                <div>**** **** **** {props.organisation.cardDetails.last4}</div>
                <div>Expires {props.organisation.cardDetails.expMonth}/{props.organisation.cardDetails.expYear}</div></div>
        );
    }


    const PaymentDisplay = () => {
        return(
            <>
                {!hasValidPaymentMethod && <i>Payment method required</i>}
                {hasValidPaymentMethod && newPaymentMethod && <i>Payment method updated <FontAwesomeIcon  icon='check-circle' /></i> }
                {hasValidPaymentMethod && !newPaymentMethod && <CreditCardDetails/>}
            </>
        )
    }

    const CreditCardDisplay = () => {
        return(
            <>
                <Row>
                    <Col xs lg="6">
                        <div><strong>Payment Method</strong></div>
                        <PaymentDisplay/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PaystackHookExample />
                    </Col>
                </Row>
            </>
        )
    }


    const EFTDisplay = () => {
        return(
            <>
                <Row>
                    <Col xs lg="6">
                        <div><strong>Payment Method</strong></div>
                        EFT
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <i>If you would like to switch to Credit Card Billing please contact support</i>
                    </Col>
                </Row>
            </>
        )
    }



    return(
        <>
            {props.organisation.paymentMethod === "paystack" ? <CreditCardDisplay/> : <EFTDisplay/>}
        </>
    )
};

export default CreditCard;