import React from 'react'
import {Badge} from 'react-bootstrap'

export default function SaleStatusBadges(props) {
    let variant = ""
    switch (props.children) {
        case "Shipped to Customer":
            variant = "success";
          break;
        case "Preparing for Customer":
            variant = "info";
          break;
        case "New Lead Time Order":
            variant = "primary";
          break;
        case "Confirmed Shipment":
            variant = "info";
          break;
          case "Shipped Shipment":
            variant = "info";
          break;
        case "Inter DC Transfer":
            variant = "info";
          break;
        case "Returned":
            variant = "warning";
          break;
        case "Cancelled - Late":
            variant = "danger";
          break;
        case "Buyable":
            variant = "success";
            break;
        case "Not Buyable":
            variant = "warning"
           break;
        case "Disabled by Seller":
            variant = "danger"
           break;
        case "Disabled by Takealot":
            variant = "danger"
           break;
        case "Draft Shipment":
          variant = "secondary"
           break;
        case "Cancelled by Customer":
            variant = "danger"
            break;
        case "New Drop Ship Order":
            variant = "primary"
            break;
        case "In Progress Drop Ship Order":
            variant = "secondary"
            break;
        case "Ready To Collect Drop Ship Order":
            variant = "secondary"
            break;
        case "Collected Drop Ship Order":
            variant = "secondary"
            break;
        default:
          variant = "secondary";
      }


    return (
        

        <Badge pill bg={variant}>{props.children}</Badge>
    )
}