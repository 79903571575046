import React from 'react';
import Remove from './Remove';


const BulkOptions = (props) => {
    return (
        <>
            <small className={'pe-3'}> {props.selectedCount} selected</small>
            <Remove count={props.selectedCount} bulkRemoveFromRepricer={props.bulkRemoveFromRepricer} />
        </>

    );
};

export default BulkOptions;