import React, {useState, useEffect,useRef} from 'react';
import Switch from "react-switch";
import {userNotification} from "../UserNotification";

const RepricingSwitch = (props) => {
    const [active, setRepricing] = useState(props.repricingEnabled)
    const isMounted = useRef(false);

    const toggleRepricing = () => setRepricing(prevActive => !prevActive)

    useEffect(() => {

        if (isMounted.current) {
            //store.addNotification(message)
            props.callback({offerId: props.id, payload:{repricingEnabled:active}})
                .then(
                    (success) => userNotification("Repricing is now " + (active ? "enabled" : "disabled"), (active ? "success" : "warning")),
                    (error) =>  {
                        userNotification(error.response.data.toString(), 'danger');
                        isMounted.current = false
                        toggleRepricing()})
        } else {
            isMounted.current = true;
        }

    }, [active]);


    return <Switch height={18} width={36} onChange={toggleRepricing} checked={active} />
};


export default RepricingSwitch;