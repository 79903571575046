import React, { Component } from "react";
import auth0Client from '../Auth/Auth';
import { pageViewed } from "../segment/analytics";

export default class Signup extends Component {
    
    
    render() {
        pageViewed("Signup")
        return(
    <>{auth0Client.signUp()}
    </>
    )
}


}
