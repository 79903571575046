import React, { Component } from "react";
import './Orders.css';

import { Col, Row,  Badge, Table } from "react-bootstrap";
import {ButtonGroup, OverlayTrigger, Tooltip} from "react-bootstrap"
import { Button, Spinner, Stack, Placeholder } from "react-bootstrap";
import OrderTransactions from "../components/OrderTransactions"
import config from "../config";
import dateFns from 'date-fns';
import {Helmet} from "react-helmet";
import { store } from 'react-notifications-component';
import SaleStatusBadge from "../components/SaleStatusBadges"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faFlag, faClock} from '@fortawesome/free-solid-svg-icons'
import NumberFormat from 'react-number-format';
import auth0Client from "../Auth/Auth"
import Search from '../components/Search';
import ChoiceList from '../components/ChoiceList'
import FilterSelector from '../components/FilterSelector'
import Paginate from '../components/Paginate';
import OrdersProductBlock from '../components/Orders/OrdersProductBlock'


import axios from 'axios';
import FeesModal from '../components/Orders/FeesModal';
import FileUpload from "../components/FileUploader";

library.add(faChevronRight)
library.add(faChevronDown)
library.add(faFlag)
library.add(faClock)


export default class Orders extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSearching: true,
      pageNum: 0,
      orders: [],
      search: null,
      status: [],
      promotion: []
    };
  }

async componentDidMount(){
  window.analytics.page('Orders');
  this.setState({ isLoading: true });
  try {
    await this.getOrders();
   
  } catch (e) {
    //alert(e + " Orders");
  }
  this.setState({ isLoading: false });
            
}


handleSearchSubmit = () => {
  this.setState({ pageNum: 0 }, () => {
    this.getOrders();     
  });
}

handleSearchChange = (value) => {
  this.setState({search: value});
}

handlePageClick = page => {
  this.setState({ pageNum: page }, () => {
    this.getOrders();     
  });
}; 


checkboxHandle = (result, type) => {
  this.setState({[(type)]: result, pageNum: 0}, this.getOrders)
} 



toggleDetails = (key) => {
  var item = {...this.state.orders}
  item[key].showDetails = !item[key].showDetails;
  this.setState({item})

}


 orders() {
    return axios.get(`${config.api.BASE_URL}/orders`, {
      params: {page:this.state.pageNum+1, q:this.state.search, saleStatus:this.state.status, promotion: this.state.promotion},
      headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`, 'shopdeploy-acc': this.props.sellerAccount.data.currentAccountId}
    });
    
  }


async getOrders(){
  this.setState({isSearching: true})
  let response = await this.orders()
  this.setState({ orders: response.data.orders, pagination: response.data.pagination  });
  this.setState({orders: response.data.orders.map(item => ({ ...item, showDetails: false }))})
  this.setState({isSearching: false})
}


  humanizeDate(date){
    if (dateFns.isToday(date)){
     return dateFns.format(date,'h:mma')
    } else {
      return dateFns.format(date,'MMM D, h:mma')
    }

  }


  copyToClipboard(text){
    navigator.clipboard.writeText(text)

    let message = {
      message: 'Copied to Clipboard',
      type: 'success',                         // 'default', 'success', 'info', 'warning'
      container: 'top-right',                // where to position the notifications
      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
      dismiss: {
        duration: 1000 
      }} 
    store.addNotification(message)

  }

  renderLoadingOrderTable(){
      return(
          <Table>
            <thead>
              <tr>
                {[...Array(5)].map(i => <th key={i}><Placeholder xs={12} size="xs" /></th>)}
              </tr>
            </thead>
            <tbody>
              {[...Array(4)].map(i => <tr key={i}> {[...Array(5)].map(i => <th key={i}><Placeholder xs={12} size="xs" /></th>)}</tr>)}
            </tbody>
          </Table>
      )
  }

    feesPending(){
        return(
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        Awaiting Fees
                    </Tooltip>  } >
                {({ ref, ...triggerHandler }) => (
                        <div {...triggerHandler} >
                            <span ref={ref}><FontAwesomeIcon style={{color:'gray'}}  icon='clock'  /></span>
                        </div>
                    )}
            </OverlayTrigger>

        )
    }


    renderNetProfitPercentage(order){

        if (order.cogs > 0 && order.fees !== null){

         return(order.netProfitPercentage)

        } else {
            return ('-')
        }


}



  renderOrderTableBody(order, i){
  return(
      <tr>
        <td style={{minWidth: '300px'}}><OrdersProductBlock {...order} /></td>
        <td><SaleStatusBadge>{order.saleStatus}</SaleStatusBadge></td>
        <td>{this.humanizeDate(order.orderDate)}</td>
        <td>{order.customer}</td>
        <td style={{textAlign:'right'}}>{order.quantity}</td>
        <td style={{textAlign:'right'}}><NumberFormat fixedDecimalScale value={order.sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2}></NumberFormat></td>
          <td style={{textAlign:'right' }}><
              NumberFormat fixedDecimalScale value={order.sellingPriceExVat} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2}></NumberFormat></td>
        <td style={{textAlign:'right', color: '#842029'}}>
            <FeesModal
                sellerAccountId={this.props.sellerAccount.data.currentAccountId}
                orderItemId={order.orderItemId}
            >
                {order.fees != null ?
                    <NumberFormat fixedDecimalScale value={order.fees*-1} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2}/> :
                    this.feesPending()
                }
            </FeesModal> </td>
        <td style={{textAlign:'right', color: '#842029'}}>
            {order.cogs > 0 ? <NumberFormat value={order.cogs} fixedDecimalScale displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2}></NumberFormat> : '-'}
        </td>
        <td style={{textAlign:'right', fontWeight: 'bold'}}><NumberFormat fixedDecimalScale value={order.netProfit} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2}></NumberFormat></td>
          <td style={{textAlign:'right', fontSize: '12px'}}>{this.renderNetProfitPercentage(order)}</td>
      </tr>

  )
  }

  renderOrderTable(){
    return(<Table hover responsive fixed size="sm" style={{fontSize: '14px'}}>
      <thead>
        <tr>
            <th>Product</th>
            <th>Status</th>
            <th>Date</th>
            <th>Customer</th>
            <th style={{textAlign:'right'}}>Quantity</th>
            <th style={{textAlign:'right'}}>Price</th>
            <th style={{textAlign:'right'}}>Price (ex)</th>
            <th style={{textAlign:'right'}}>Fees</th>
            <th style={{textAlign:'right'}}>COGS</th>
            <th style={{textAlign:'right'}} >Net</th>
            <th style={{textAlign:'right'}} >%</th>
      </tr>
      </thead>
      <tbody style={{verticalAlign:'middle'}}>
      {this.state.orders.map((order, i) => this.renderOrderTableBody(order,i) )}
      </tbody>
    </Table>)
  }


  renderOrdersGrid(orders){
    return(
        <>
         {this.state.isSearching ?
            <Spinner className="spinner-table" animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner> : ""
            }
        <Row className="orders-table-header">
        <div className="fixedWidthBox"> </div> 
        <Col lg={8} xs={7}>
          <Row sm={12} className="align-items-center ">
          
           
              <Col lg={6} sm={12}>
                <Row>
                <Col lg={12} >Product</Col>
         
                </Row>
              </Col >
      
            <Col lg={6} sm={12}>
              <Row>
               <Col lg={6} className="d-none d-lg-block" >Status</Col>
              <Col lg={6} className="d-none d-lg-block">Date</Col>
              
              </Row>
            </Col>
          </Row>
        </Col>
        
        <Col lg={3} xs={4}>
          <Row sm={12} lg={2} className="align-items-center justify-content-center">
            <Col lg={6} className="d-none d-lg-block">Customer</Col>
            <Col lg={3} xs={6}>QTY</Col>
            <Col lg={3} xs={6} >Price</Col>
          </Row>
        </Col>
        </Row>


       { (orders).map(
        (order, i) =>
        <div>
      <Row className={`orders-table align-items-center ${!this.state.isSearching ? "" : "opacity-test"}`}> 
      <div style={{cursor: 'pointer'}} className="fixedWidthBox" >
        <FontAwesomeIcon  icon={order.showDetails? 'chevron-down': 'chevron-right'} onClick={() => {this.toggleDetails(i)}}/> 
      </div> 

        <Col lg={8} xs={7}>
          <Row sm={12} className="align-items-center ">
          
              <Col lg={6} sm={12}>
                <Row>
                

                </Row>
              </Col >
      
            <Col lg={6} sm={12}>
              <Row>
               <Col lg={6}><SaleStatusBadge>{order.saleStatus}</SaleStatusBadge></Col>
              <Col lg={6}>{this.humanizeDate(order.orderDate)}</Col>
              
              </Row>
            </Col>
          </Row>
        </Col>
        
        <Col lg={3} xs={4}>
          <Row sm={12} lg={2} className="align-items-center justify-content-center">
            <Col lg={6} className="d-none d-lg-block">{order.customer}</Col>
            <Col lg={3} xs={6}>{order.quantity}</Col>
            <Col className="pe-0" lg={3} xs={6}  >
              {/* <Col lg={12} className="pr-0"> R{order.attributes.sellingPrice}</Col>
              <Col lg={12} className="pr-0 order-fees">{order.attributes.fees}</Col> */}
              <div><NumberFormat value={order.sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'R'}></NumberFormat></div>
              <div className="order-fees"><NumberFormat value={order.fees} displayType={'text'} thousandSeparator={true} prefix={'R'} decimalScale={2}></NumberFormat></div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{background: '#E4E7EB'}}
    >{order.showDetails && <OrderTransactions props = {this.props.sellerAccount.data}>{order.orderItemId}</OrderTransactions>} </Row>
 </  div> )}
 
        
        
        </>
     
    
    
    )
  }

  renderFilters(){
    
    
    const options = [
      { value: 'Shipped to Customer', label: 'Shipped to Customer'},
      { value: 'Preparing for Customer', label: 'Preparing for Customer' },
      { value: 'Inter DC Transfer', label: 'Inter DC Transfer' },
      { value: 'New Lead Time Order', label: 'New Lead Time Order' },
      { value: 'Draft Shipment', label: 'Draft Shipment' },
      { value: 'Confirmed Shipment', label: 'Confirmed Shipment' },
      { value: 'Shipped Shipment', label: 'Shipped Shipment' },
      { value: 'Checked In Shipment', label: 'Checked In Shipment' },
      { value: 'Received Shipment', label: 'Received Shipment' },
      { value: 'Cancelled by Customer', label: 'Cancelled by Customer' },
      { value: 'Cancelled by Seller', label: 'Cancelled by Seller' },
      { value: 'Cancelled by Takealot', label: 'Cancelled by Takealot' },
      { value: 'Cancelled - Late', label: 'Cancelled - Late' },
      { value: 'Returned', label: 'Returned' },
      { value: 'New Drop Ship Order', label: 'New Drop Ship Order' },
      { value: 'In Progress Drop Ship Order', label: 'In Progress Drop Ship Order' },
      { value: 'Ready To Collect Drop Ship Order', label: 'Ready To Collect Drop Ship Order' },
      { value: 'Collected Drop Ship Order', label: 'Collected Drop Ship Order' }
    ]



      //const [selected, setSelected] = useState([]);
      return(  

        <>
          <Stack direction="horizontal" gap={3} className='mb-2'>
              <Search 
              submitCallback={this.handleSearchSubmit}
              updateCallback={this.handleSearchChange}
              />
              <div className="vr" />
            <FilterSelector>
              <ChoiceList 
                title='Status'
                selected={this.state.status}
                onChange={(e) => {this.checkboxHandle(e, 'status')}}
                options={options}
                allowMultiple
              />
              <ChoiceList 
                title='Sold On Promotion'
                selected={this.state.promotion}
                onChange={(e) => {this.checkboxHandle(e, 'promotion')}}
                options={[
                  { value: 'true', label: 'Yes'},
                  { value: 'false', label: 'No' }]}             
              />
      
            </FilterSelector>
          </Stack>

        </>)}


  render() {
    return (
      // <main role="main" className="col-sm-12 main pt-3">
      <div className="Orders">
         <Helmet>
            <title>Orders</title>
          </Helmet>
          <Stack direction="horizontal" gap={3} >
       <h1>Orders</h1>
          <div className="ms-auto"><FileUpload currentAccountId={this.props.sellerAccount.data.currentAccountId} uploadType={'costs'}/> </div>
          </Stack>
           {!this.state.isLoading ? this.renderFilters() : <div></div>}
           {!this.state.isLoading ? this.renderOrderTable(): this.renderLoadingOrderTable()}
            {!this.state.isLoading ? <div className="mt-3"><Paginate  page={this.state.pageNum} onPageChange={this.handlePageClick}></Paginate></div> : "" }
       
      </div>
      // </main>
    );
  }


}