import React from 'react'
import { ButtonGroup, Image, Button, Col, Row,  ButtonToolbar, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faCopy } from '@fortawesome/free-solid-svg-icons'
import { store } from 'react-notifications-component';
import { differenceInMinutes, differenceInHours } from 'date-fns'




library.add(faExternalLinkAlt)
library.add(faCopy)


const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)

  let message = {
    message: 'Copied to Clipboard',
    type: 'success',                         // 'default', 'success', 'info', 'warning'
    container: 'top-right',                // where to position the notifications
    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
    dismiss: {
      duration: 1000 
    }} 
  store.addNotification(message)

}

const ProductTitleBlock = (props) => {
  
  
    return(
    <Row className={`orders-table align-items-center ${!props.isSearching ? "" : "opacity-test"}`}> 
    <div className="fixedWidthImage">
      <Image src={props.offer.imageUrl}/>
    </div>   <Col lg={4} xs={6}>
        <Row className="align-items-center" >
          
           <Col lg={12} sm={12}>  
            <Col lg={12}>{props.offer.brand}</Col>
            <Col lg={12}><strong>{props.offer.title}</strong></Col>
            <Col lg={12} md={6}> 

            <ButtonToolbar className="mt-1" aria-label="Toolbar with button groups">
              <ButtonGroup className="me-1 mb-1">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                      Click to copy SKU
                  </Tooltip>  } >
                    <Button variant="sku" onClick={() => copyToClipboard(props.offer.sku) } ><FontAwesomeIcon size='s' icon="copy" /> {props.offer.sku} </Button>
              </OverlayTrigger>
            </ButtonGroup>
               
               
                <ButtonGroup className="me-2 mb-1"> 
                <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                      Click to copy TSIN
                  </Tooltip>  } ><Button variant="tsin" onClick={() => copyToClipboard(props.offer.tsin)}><FontAwesomeIcon size='s' icon="copy" /> {props.offer.tsin}</Button></OverlayTrigger>
        
                  <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                      View on Takealot
                  </Tooltip>  } ><Button variant="tsin" href ={props.offer.takealotUrl} target='_blank'> <FontAwesomeIcon size='s' icon="external-link-alt" /></Button></OverlayTrigger>
        
                  </ButtonGroup>
                    {props.repricing && 
                          <ButtonGroup className="ml-1 mb-1 me-2">
                          <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              {props.offer.repriceAdded? 'Remove from Repricing': 'Add to Repricing'} 
                            </Tooltip>  } ><Button variant="repricing" onClick={() => props.repricingCallback({offer:props.offer.id,enableRepriceAdd: !props.offer.repriceAdded} )} active={props.offer.repriceAdded} ><FontAwesomeIcon size='s' icon="chart-line" /></Button></OverlayTrigger>
                            </ButtonGroup>
                    }

                      {props.isSuperAdmin && props.offer.repricingLastCrawledAt !== null &&
                      
                          <ButtonGroup className="ml-1 mb-1 me-2">
                          <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Offer last checked
                            </Tooltip>  } ><Button variant="time"> { differenceInMinutes(new Date(),props.offer.repricingLastCrawledAt) <= 120 ? differenceInMinutes(new Date(),props.offer.repricingLastCrawledAt) + " mins ago" : 'Outdated'}</Button></OverlayTrigger>
                            </ButtonGroup>
                    }
                     
                    {props.isSuperAdmin && props.offer.lastCrawlTimestamp !== null &&
                      
                      <ButtonGroup className="ml-1 mb-1">
                      <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          PLID last checked
                        </Tooltip>  } >
                         {/* {console.log(new Date().getUTCDate())}
                         {console.log(props.offer.lastCrawlTimestamp)} */}
                        {/* <Button variant="time"> { differenceInHours(new Date(), new Date(props.offer.lastCrawlTimestamp))}</Button> */}
                          <Button variant="time"> { differenceInHours(new Date(), new Date(props.offer.lastCrawlTimestamp)) <= 120 ? differenceInHours(new Date(),new Date(props.offer.lastCrawlTimestamp)) + " hours ago" : 'Product Outdated'}</Button>
                          </OverlayTrigger>
                        </ButtonGroup>
                }


                  </ButtonToolbar>
                 
       



            </Col>
          </Col>       
        </Row>         
        </Col>{props.children}
        </Row>)


}
export default ProductTitleBlock


